<template>
  <article
    class="product-card-mobile"
    data-b24-product-card
    :data-b24-id="getProductProperty(product, productProperty.id)"
    :data-b24-base-id="getProductProperty(product, productProperty.baseId)"
    :data-b24-price="getProductProperty(product, productProperty.price)"
    :data-b24-name="getProductProperty(product, productProperty.name)"
    :data-b24-brand="getProductProperty(product, productProperty.brand)"
    :data-b24-discount="getProductProperty(product, productProperty.discount)"
    :data-b24-category="getProductProperty(product, productProperty.category)"
    :data-b24-available="getProductProperty(product, productProperty.available)"
    :data-b24-preorder="getProductProperty(product, productProperty.preorder)"
    :data-b24-index="productIndex"
    :data-b24-item-list-name="$route.name"
  >
    <slot name="rating"></slot>
    <div class="product-card-mobile__image-block">
      <card-labels
        v-if="isShowLabels"
        :labels="labels"
        class="_mobile"
      />
      <product-card-link
        :link="link"
        :title="product.name"
        :handler="handlerClickProduct"
        class="product-card-mobile__image-link"
      >
        <app-picture
          class-picture="product-card-mobile__picture"
          :alt="`Купить ${product.name} — Фото №1`"
          class-img="product-card-mobile__image"
          :fallback-image="product.fallbackImage"
          :item="product.images"
          :is-adult="checkIsAdult && product.isAdult"
          :show-adult-badge="checkIsAdult && product.isAdult"
        />
      </product-card-link>
      <badge-best-price-mobile v-if="isShowBestPriceBadge" />
    </div>
    <div class="product-card-mobile__container">
      <div class="product-card-mobile__content">
        <card-price
          v-if="product.isAvailable"
          class="product-card-mobile__price"
          :price="product.prices.price"
          :old-price="product.prices.base"
          :discount="product.discountPercent"
          :show-discount="showDiscountNearPrice"
          :is-line="isLinePrice"
        />
        <product-card-link
          :link="link"
          :title="product.name"
          :handler="handlerClickProduct"
          class="product-card-mobile__title"
        >
          {{ product.name }}
        </product-card-link>
        <author-list
          v-if="product.authorList.length"
          :author-list="product.authorList"
          class="product-card-mobile__authors-holder"
        />
      </div>
      <div v-if="showButtons" class="product-card-mobile__button-block">
        <card-main-button
          class="product-card-mobile__button"
          :product-id="product.id"
          :quantity="1"
          :link="link"
          :is-added-to-cart="isAddedToCart"
          :is-pre-order="product.isPreOrder"
          :is-not-available="!product.isAvailable"
          :is-available-button-short="isAvailableButtonShort"
          :is-certificate="product.isCertificate"
          :is-adult="product.isAdult"
          @clickNotAvailable="handlerClickNotAvailable"
        />
        <add-favorites
          class="product-card__add-favorites"
          :product-id="product.id"
          :price="product.prices.price"
          :old-price="product.prices.base"
          :category-id="categoryId"
          :book-id="bookId"
          :tbk-code="tbkCode"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useProductCard from '@/compositions/catalog/useProductCard';

import CardPrice from '@/components/ProductCard/CardPrice';
import CardMainButton from '@/components/ProductCard/CardMainButton';
import AuthorList from '@/components/ProductCard/AuthorList';
import AppPicture from '@/components/AppPicture';
import AddFavorites from '@/components/AddFavorites';
import BadgeBestPriceMobile from '@/components/ProductCard/BadgeBestPrice/BadgeBestPriceMobile';
import CardLabels from '@/components/ProductCard/CardLabels';
import ProductCardLink from '@/components/ProductCard/ProductCardLink/index.vue';

export default defineComponent({
  name: 'ProductCardMobile',
  components: {
    AppPicture,
    CardPrice,
    CardMainButton,
    AuthorList,
    AddFavorites,
    BadgeBestPriceMobile,
    CardLabels,
    ProductCardLink,
  },
  props: {
    product: { type: Object, required: true },
    withLabel: { type: Boolean, default: true },
    withBadge: { type: Boolean, default: true },
    showButtons: { type: Boolean, default: true },
    paramsLink: { type: Object, default: () => ({}) },
    // использовать ли короткое название кнопки "Нет в наличии"
    isAvailableButtonShort: { type: Boolean, default: false },
    isLinePrice: { type: Boolean, default: true },
    productIndex: { type: Number, default: 1 },
  },
  setup(props, context) {
    const productCardComposition = useProductCard(props, context);

    return {
      ...productCardComposition,
    };
  },
});
</script>

<style lang="less">
.product-card-mobile {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 156px;
  height: 100%;

  @media @375 {
    width: 152px;
  }

  @media @355 {
    width: 136px;
  }

  &__image-block {
    position: relative;
    display: flex;
    height: 200px;
    margin-bottom: @size-x2;
  }

  &__image-link {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 100%;
  }

  &__picture {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  &__content {
    margin-bottom: @size-x2;
  }

  &__title {
    .p();
    .overflow-ellipsis-lines();

    margin: 0;
    max-height: 48px;
    text-decoration: none;
    color: @black;
    transition: @A color;

    &._tap {
      color: darken(@gray, 12%);
    }
  }

  &__authors-holder {
    .overflow-ellipsis();
  }

  &__button-block {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    align-items: center;
  }

  &__button {
    max-width: 136px;
    margin-right: auto;
  }

  &__button-control {
    padding: @main-size;
    width: 44px;
  }
}
</style>
