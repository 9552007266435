import { ref, useContext } from '@nuxtjs/composition-api';

import { useStorage } from '@vueuse/core';
import getTinkoffID from '@/utils/getTinkoffID';
import getOptionsSetCookie from '@/utils/getOptionsSetCookie';
import {
  CUSTOMER_ID_KEY,
  TINKOFF_INITIAL_LOCATION_KEY,
  TINKOFF_REDIRECT_URI,
  TINKOFF_REDIRECT_URI_KEY,
} from '@/utils/constants/authorizationConstants';
import { LOCAL_CONST_CUSTOMERS } from '@/utils/constants/globalConst';

export const useLoginByTinkoffId = () => {
  const { $config, $cookies } = useContext();
  const customerId = useStorage(LOCAL_CONST_CUSTOMERS, '');
  const phoneInput = ref(null);
  const submitButton = ref(null);

  const initialWidget = async () => {
    const redirectUri = `${window.location.origin}${TINKOFF_REDIRECT_URI}`;

    $cookies.set(
      TINKOFF_INITIAL_LOCATION_KEY,
      window.location.href,
      getOptionsSetCookie($config.domainCookie),
    );

    $cookies.set(
      TINKOFF_REDIRECT_URI_KEY,
      redirectUri,
      getOptionsSetCookie($config.domainCookie),
    );

    $cookies.set(
      CUSTOMER_ID_KEY,
      customerId.value,
      getOptionsSetCookie($config.domainCookie),
    );

    const tidSdk = await getTinkoffID()
      .then(TidSDK => new TidSDK({
        redirectUri,
        new_session: 'true',
        responseType: 'code',
        clientId: $config.tinkoffIdClientId,
        state: customerId.value,
      }));

    if (tidSdk?.addWL) {
      tidSdk.addWL({
        target: '_self',
        input: phoneInput.value,
        actionElement: submitButton.value,
        percentageAuth: 100,
      });
    }
  };

  const clickHandler = () => {
    submitButton.value.click();
  };

  return {
    phoneInput,
    submitButton,
    initialWidget,
    clickHandler,
  };
};
