// @ts-check
/// <reference path="./typedefs.js" />
/// <reference path="../../../typedefs.js" />

import { deserialize } from 'deserialize-json-api';
import HttpClient from '@/utils/clients/clientBookMain';
import formattedProduct from '@/utils/formatters/formattedProduct';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { formattedCatalogCategoryListForFacet } from '@/utils/formatters/formattedCatalog';
import { buildErrorCollection, buildCollection, buildElement } from '@/utils/formatters/processingOpenApi';
import { SUCCESS_STATUS } from '@/utils/constants/globalConst';
import {
  CatalogProductPropertyNameEnum,
  errorsGetCatalogCategoryInfo,
  errorSubscription,
} from '@/utils/constants/catalogConstants';

export default class CatalogService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получение каталожных разделов в виде меню
   * @return {Promise<unknown>}
   */
  getMenu() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/catalog-menu/?include=product,product.author')
      .then(({ data: dataRes }) => {
        const { data } = deserialize(dataRes);
        resolve(data);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Получение списка товаров
   * @param {Object} data
   * @param {string} [data.match=''] - Фильтр match для сфинкса
   * @param {string} [data.filterPreset=''] - Заготовленный пресет сортировки
   * @param {string} [data.filter=''] - Фильтр
   * @param {string} [data.sortPreset=''] - Заготовленный пресет сортировки
   * @param {number} [data.pageSize=15] - Количество товаров на странице
   * @param {number} [data.pageNumber=1] - Номер страницы в пагинации
   * @param {boolean} [data.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [data.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [data.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [data.isGetProductText=false] - Нужно ли получить описание товара
   * @param {string} [data.getQuotes=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @param {boolean} [data.isGetPublishingHouse=true] - Нужно ли получить издателя товара
   * @param {boolean} [data.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [data.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @param {boolean} [data.isGetProductStats=false] - Нужно ли получать статистику по товару
   * @param {boolean} [data.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @return {Promise<ProductList|Error|ErrorObject>}
   */
  getProducts(data = {}) {
    const {
      match = '',
      filterPreset = '',
      filter = '',
      sortPreset = '',
      pageSize = 15,
      pageNumber = 1,
      getQuotes = '',
      isGetAuthors = true,
      isGetSeries = false,
      isGetBadge = true,
      isGetProductText = false,
      isGetPublishingHouse = true,
      isGetCategory = true,
      isGetParentCategory = false,
      isGetProductStats = false,
      isGetOrsProduct = true,
    } = data;

    const include = [];
    if (isGetAuthors) include.push('author');
    if (isGetSeries) include.push('series');
    if (isGetBadge) include.push('badge');
    if (isGetProductText) include.push('productText');
    if (getQuotes) include.push(getQuotes);
    if (isGetPublishingHouse) include.push('publishingHouse');
    if (isGetCategory) include.push('category');
    if (isGetParentCategory) include.push('category.parentCategory');
    if (isGetProductStats) include.push('productStats');
    if (isGetOrsProduct) include.push('orsProduct');

    let dataRequest = { 'per-page': pageSize, page: pageNumber };

    if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
    if (filter) dataRequest = { ...dataRequest, filter };
    if (sortPreset) dataRequest = { ...dataRequest, sortPreset };
    if (match) dataRequest = { ...dataRequest, match };
    if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/products/', dataRequest)
      .then(({ data: { data: dataRes } }) => {
        const { list, pagination } = buildCollection(dataRes, true);
        const items = list.map((item) => {
          const element = formattedProduct(item);
          if (isGetProductText) {
            element.productText = {
              advertisingBlockHeader: item.relationships.productText.advertisingBlockHeader,
              advertisingBlockText: item.relationships.productText.advertisingBlockText,
              detailDescription: item.relationships.productText.detailDescription,
              additionalDescription: item.relationships.productText.additionalDescription,
              showAdditionalDescription: item.relationships.productText.showAdditionalDescription,
            };
          }

          if (isGetProductStats) {
            element.productStats = {
              purchased: item.relationships.productStats.purchased,
              reviewTotal: item.relationships.productStats.reviewTotal,
            };
          }

          if (isGetSeries && Array.isArray(item.series)) {
            element.series.forEach((seriesItem) => {
              if (+seriesItem.numBooks <= 1) {
                seriesItem.link = null;
              }
            });
          }

          return element;
        }) || [];
        return resolve({
          items,
          pagination,
        });
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Получение фасетов каталога
   * @param {Object} data
   * @param {string} data.pageType - тип страницы
   * @param {string} [data.objectId] - идентификатор типа страницы, если есть
   * @param {string} [data.filter] - пользовательский фильтр в виде author_id=184373,184372;saleleader=1;
   * @param {string} [data.filterPreset] - пресест фильтров
   * @param {string} [data.match] - поисковая строка
   * @return {Promise<{items: *[]}>}
   */
  getFacets(data) {
    const {
      pageType,
      objectId,
      filter = '',
      filterPreset = '',
      match = '',
    } = data;

    let dataRequest = { pageType };
    if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
    if (filter) dataRequest = { ...dataRequest, filter };
    if (match) dataRequest = { ...dataRequest, match };
    if (objectId) dataRequest = { ...dataRequest, objectId };

    return this.mainHttp.get('/api/v1/internal/catalog/facet/', dataRequest)
      .then(({ data: { data: dataRes } }) => {
        const { list: items } = buildCollection(dataRes);
        const filteredItems = items?.filter(i => i.id !== CatalogProductPropertyNameEnum.ONLY_SHOP) || [];
        return { items: filteredItems };
      })
      .catch((e) => {
        if (e.response) {
          // если больше одной ошибки возвращаем все
          if (e.response.data?.errors?.length > 1) {
            const errors = [...e.response.data.errors];
            return Promise.reject(errors);
          }
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Получение блока сниппетов
   * @param {Object} data
   * @param {string} data.snippetId - идентификатор блока сниппетов
   * @return {Promise<SnippetList>}
   */
  getSnippets(data) {
    return new Promise((resolve, reject) => {
      const { snippetId } = data;
      if (!snippetId) reject(new Error('[CatalogService:getSnippets] snippetId не должен быть пустым'));

      return this.mainHttp.get(`/api/v1/snippets/${snippetId}/`)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          const mainData = {
            id: resFormatted.id,
            title: resFormatted.name,
            snippets: resFormatted.relationships?.snippetItem || [],
          };
          return resolve({ ...mainData });
        })
        .catch((e) => {
          if (e.response) {
            // если больше одной ошибки возвращаем все
            if (e.response.data?.errors?.length > 1) {
              const errors = [...e.response.data.errors];
              return reject(errors);
            }
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получение поисковых подсказок фасета
   * @param {Object} [data={}]
   * @param {string} data.url - урл запроса
   * @param {string} data.type - тип запрашиваемых подсказок
   * @param {string} [data.filter=''] - пользовательский фильтр в виде author_id=184373,184372;saleleader=1;
   * @param {string} [data.filterPreset=''] - пресест фильтров
   * @param {string} [data.match=''] - поисковая строка подсказки
   * @param {string} [data.searchMatch=''] - глобальная поисковая строка
   * @param {string} [data.pageType=''] - тип раздела каталога (rating_common, tags, etc.)
   * @param {string} [data.objectId=''] - id категории каталога
   * @return {Promise<{item: *, name, value: *}[]>}
   */
  getSuggestionFromFacet(data = {}) {
    const {
      url,
      type,
      filter = '',
      filterPreset = '',
      match = '',
      searchMatch = '',
      pageType = '',
      objectId = '',
    } = data;

    let dataRequest = { match };
    if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
    if (filter) dataRequest = { ...dataRequest, filter };
    if (searchMatch) dataRequest = { ...dataRequest, searchMatch };
    if (pageType) dataRequest = { ...dataRequest, pageType };
    if (objectId) dataRequest = { ...dataRequest, objectId };

    return this.mainHttp.get(url, dataRequest)
      .then(({ data: { data: dataRes } }) => {
        const { list: items } = buildCollection(dataRes);
        return items
          .filter(item => item.relationships)
          .map(item => ({
            name: item.relationships[type]?.fullName || item.relationships[type]?.name,
            value: item.id,
            item,
          }));
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * @typedef {Object} CatalogCategoryInfo
   * @property {string} name - название категории
   * @property {number} count - кол-во элементов в категории
   * @property {string} code - символьный код категории
   * @property {string|null} catalogSectionText - детальный текст категории
   * @property {Object|null} seoMeta - SEO данные категории
   * @property {Array|null} crumbs - хлебные крошки категории
   * @property {Array|null} parentCategory - родительские категории
   */

  /**
   * Получение данных категории по её идентификатору
   * @param {Object} [data={}]
   * @param {number} data.categoryId - идентификатор категории
   * @param {boolean} [data.isGetBreadcrumbs] - нужно ли получать хлебные крошки
   * @param {boolean} [data.isGetSeoMeta] - нужно ли получать SEO данные
   * @param {boolean} [data.isGetTbkCode] - нужно ли получать tbkCode
   * @param {boolean} [data.isGetCatalogSectionText] - нужно ли получать детальный текст категории
   * @param {boolean} [data.isGetCatalogParentCategory] - нужно ли получать родительские категории
   * @return {Promise<CatalogCategoryInfo|Error|ErrorObject>}
   */
  getCatalogCategoryInfo(data = {}) {
    return new Promise((resolve, reject) => {
      const {
        categoryId,
        isGetBreadcrumbs = false,
        isGetSeoMeta = false,
        isGetTbkCode = true,
        isGetCatalogSectionText = false,
        isGetCatalogParentCategory = false,
      } = data;

      if (!categoryId) reject(new Error('[CatalogService:getCatalogCategoryInfo] categoryId не должен быть пустым'));

      const include = [];
      if (isGetBreadcrumbs) include.push('breadcrumbs');
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetTbkCode) include.push('orsCategory');
      if (isGetCatalogSectionText) include.push('catalogSectionText');
      if (isGetCatalogParentCategory) include.push('parentCategory');

      let dataRequest = {};
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

      return this.mainHttp.get(`/api/v1/internal/catalog/category/${categoryId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          const mainData = {
            name: resFormatted.name,
            count: resFormatted.code,
            code: resFormatted.code,
            tbkCode: resFormatted.relationships?.orsCategory?.tbkCode || null,
          };
          return resolve({
            ...mainData,
            catalogSectionText: resFormatted.relationships?.catalogSectionText?.detailDescription
              || null,
            seoMeta: resFormatted.relationships?.seoMeta || null,
            crumbs: resFormatted.relationships?.breadcrumbs
              ? formattedPageBreadcrumbs(resFormatted.relationships?.breadcrumbs)
              : null,
            parentCategory: resFormatted.relationships?.parentCategory
              ? formattedCatalogCategoryListForFacet(
                resFormatted.relationships.parentCategory,
                mainData,
              )
              : null,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, errorsGetCatalogCategoryInfo);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получаем список категорий каталога привязанных к конкретному разделу
   * @param {Object} options
   * @param {string|number} [options.categoryId=''] - идентификатор раздела
   * @param {array} [options.ids[]=[]] - массив идентификаторов категорий
   * @param {boolean} [options.withAuthor=false] - только категории с авторами
   * @param {boolean} [options.withSelf=false] - получать родительскую категорию
   * @param {boolean} [options.isGetFavorite=false] - нужно ли получать любимые жанры
   * @returns {Promise<CatalogCategoryList>}
   */
  getCatalogCategoryListByCategoryId(options = {}) {
    return new Promise((resolve, reject) => {
      const {
        categoryId = '',
        ids = [],
        withAuthor = false,
        withSelf = false,
        isGetFavorite = false,
      } = options;

      const include = [];

      if (isGetFavorite) include.push('customerReferenceAttraction');

      let dataRequest = {};

      if (categoryId) dataRequest.id = categoryId;
      if (ids) dataRequest.ids = ids;
      if (withAuthor) dataRequest = { ...dataRequest, withAuthor };
      if (withSelf) dataRequest = { ...dataRequest, withSelf };
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

      return this.mainHttp.get('/api/v1/internal/catalog/category/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const favoriteIds = dataRes.included?.map(item => item.attributes.itemId);
          const { list } = buildCollection(dataRes);
          return resolve(list.map(item => ({
            id: item.id,
            title: item.name,
            code: item.code,
            link: `/catalog/${item.code}-${item.id}/`,
            isParent: item.depthLevel === 2,
            parentId: String(item.parentId),
            disabled: favoriteIds?.includes(item.parentId) && item.depthLevel === 3,
            checked: !!item.relationships?.customerReferenceAttraction?.id,
            uuid: item.relationships?.customerReferenceAttraction?.id,
          })));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Подписывает email на появление товара по id
   * @param {Object} [params={}]
   * @param {number|string} params.productId
   * @param {string} params.userEmail
   * @return {Promise<unknown>}
   */
  notifyProductArrival(params = {}) {
    return new Promise((resolve, reject) => {
      const {
        productId,
        userEmail,
      } = params;

      return this.mainHttp.post('/api/v1/catalog/notify_product_arrival/', {
        EMAIL: userEmail,
        PRODUCT_ID: productId,
      })
        .then(({ data: { status } }) => {
          if (status === SUCCESS_STATUS) {
            resolve();
          } else {
            reject(new Error('Приносим свои извинения, в данный момент мы не можем сообщить вам когда книга появится в наличии. Попробуйте повторить попытку позднее'));
          }
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, errorSubscription);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  getCatalogCategoryList(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageSize = 15,
        pageNumber = 1,
        filterPreset = '',
        filter = '',
        sortPreset = '',
      } = payload;

      const dataRequest = {
        PAGE: pageNumber,
        PAGE_SIZE: pageSize,
      };

      if (filterPreset) dataRequest.FILTER_PRESET = filterPreset;
      if (filter) dataRequest.FILTER = filter;
      if (sortPreset) dataRequest.SORT_PRESET = sortPreset;

      return this.mainHttp.get('/api/v1/catalog/lists/categories/', dataRequest)
        .then(({ data: { data: dataRes } }) => resolve({
          list: dataRes.items.map(item => ({
            id: item.id,
            title: item.name,
            link: `/catalog/${item.code}-${item.id}/`,
            code: item.code,
            swgIcon: item?.swgIcon || null,
            image: {
              type: 'book24',
              x1: item?.picture?.src,
              x2: null,
              webpX1: null,
              webpX2: null,
            },
          })),
        }))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получает поисковые подсказки
   * @param {Object} payload
   * @param {string} payload.q
   * @param {number} payload.limitSuggest
   * @param {number} payload.limitSection
   * @param {number} payload.limitProduct
   * @param {number} payload.limitPodborki
   * @return {Promise<unknown>}
   */
  getSuggestions(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        q = '',
        limitSuggest = '',
        limitSection = '',
        limitProduct = '',
        limitPodborki = '',
      } = payload;

      const dataRequest = {
        q,
        limit_suggest: limitSuggest,
        limit_section: limitSection,
        limit_product: limitProduct,
        limit_podborki: limitPodborki,
      };

      return this.mainHttp.get('/api/v1/catalog/search/suggests/', dataRequest)
        .then(({ data: { data: dataRes, status, message } }) => {
          if (status === 'OK') {
            resolve(dataRes);
          }
          reject(message);
        })
        .catch(e => reject(e));
    });
  }

  /**
   * Получает популярные поисковые запросы
   * @return {Promise<unknown>}
   */
  getPopularPhrases() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/catalog/search/popular-phrase/')
      .then(({ data: { data: dataRes } }) => {
        const { list } = buildCollection(dataRes);
        resolve(list);
      })
      .catch(e => reject(e)));
  }
}
