import {
  computed,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  useStore,
} from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';
import {
  ERROR_CODE_HAS_ALREADY_BEEN_SENT,
  PHONE_LENGTH,
  PHONE_MACK,
  REGISTRATION,
  ERROR_ALREADY_CONFIRM,
  USER_BLOCKED_ERROR_TEXT,
  CODE_TIMEOUT,
  ERROR_CAPTCHA_INVALID,
  ERROR_USER_BLOCKED,
  ERROR_VERIFY_CODE,
  LOGIN,
  PROMO_CODE,
  TINKOFF_AUTH_ERROR_TEXT,
} from '@/utils/constants/authorizationConstants';
import { getLatestVerificationIssue } from '@/utils/getLatestVerificationIssue';
import {
  analyticAuthRequestCode,
  analyticAuthIncorrectCode,
  analyticAuthCorrectCode,
  analyticAuthSuccess,
  analyticAuthRegFormOpen,
  analyticAuthFormSubmitSuccess,
  analyticAuthFormSubmitError,
  analyticAuthFormButtonClick,
  analyticAuthOpenCaptcha,
} from '@/utils/analytics/analyticEvents';

import { useSms } from '@/compositions/authorization/useSms';
import { useLoginByTinkoffId } from '@/compositions/authorization/useLoginByTinkoffId';
import useHttpClient from '@/compositions/useHttpClient';
import useAuthorization from '@/compositions/authorization/useAuthorization';
import useCaptcha from '@/compositions/authorization/useCaptcha';

const useLogin = () => {
  const { auth, product } = useHttpClient();
  const authorization = useAuthorization();
  const captcha = useCaptcha();
  const sms = useSms();
  const tinkoffId = useLoginByTinkoffId();
  const store = useStore();

  const localState = reactive({
    captchaKey: '',
    phone: '',
    codeSent: false,
    textError: '',
    isAvailable: true,
    isBlocked: false,
    isLoading: false,
    isBlockByLostAuthRequestId: false,
    isShowCaptcha: false,
    phoneMask: false,
  });

  const phoneIsComplete = computed(() => (localState.phone.length === PHONE_LENGTH));

  const buttonText = computed(() => (localState.codeSent ? 'Продолжить' : 'Получить код'));

  const buttonDisabled = computed(() => {
    if (localState.codeSent) {
      return !!(!sms.codeIsComplete.value
      || localState.isBlockByLostAuthRequestId
      || localState.isLoading);
    }
    return !!(localState.isLoading || !phoneIsComplete.value || localState.isBlocked);
  });

  const checkAuth = async (setPhone) => {
    await auth.checkAuth(authorization.authIssueId.value)
      .then((res) => {
        const latestVerificationIssues = getLatestVerificationIssue(res.transitions);
        if (!latestVerificationIssues) return;
        const { phoneNumber = '+7' } = latestVerificationIssues;
        sms.setTimer(res.transitions);

        if (setPhone) {
          const phoneNumberWithoutCode = phoneNumber.slice(2);
          localState.phone = localState.phoneMask.apply(phoneNumberWithoutCode);
        }

        if (!sms.timeToResend.value) {
          authorization.updateAuthData();
          return;
        }

        const phoneNumberWithoutCode = phoneNumber.slice(2);
        localState.phone = localState.phoneMask.apply(phoneNumberWithoutCode);
        localState.codeSent = true;
      })
      .catch((e) => {
        captureException(e);
        localState.isLoading = false;
      });
  };

  const sendCode = async ({
    strategy = 'byPhone',
    useV2Captcha = false,
    captchaV2,
    byPhone = true,
  } = {}) => {
    const captchaType = useV2Captcha === true ? captchaV2 : captcha.captchaOptions.value;
    const payload = {
      strategy,
      phone: localState.phone,
      visitorId: authorization.visitorId,
      brsign: {
        type: 'reCaptcha',
        payload: {
          token: captchaType.captchaToken,
          action: captchaType.captchaAction,
          version: captchaType.captchaVersion,
        },
      },
    };

    await auth.createAuthIssue(payload)
      .then(async (res) => {
        authorization.updateAuthData(res);
        sms.setTimer(res.transitions);
        localState.isShowCaptcha = false;
        localState.captchaKey = strategy;
        captcha.recaptchaWidget.value = null;

        if (byPhone) localState.codeSent = true;
        else tinkoffId.clickHandler();
      })
      .catch(async (error) => {
        if (error.code === ERROR_USER_BLOCKED) {
          localState.isBlocked = true;
          localState.textError = localState.isBlocked ? USER_BLOCKED_ERROR_TEXT : '';
          localState.isLoading = false;
        }

        if (error.code === ERROR_CAPTCHA_INVALID) {
          analyticAuthOpenCaptcha(`Запрос кода: ${strategy}`);
          localState.isShowCaptcha = true;
          if (captcha.recaptchaWidget.value !== null) await captcha.resetReCaptcha();
          if (captcha.recaptchaWidget.value === null) {
            await captcha.renderReCaptcha(async (captchaToken) => {
              await sendCode({
                byPhone,
                strategy,
                useV2Captcha: true,
                captchaV2: {
                  captchaToken,
                  captchaAction: 'sendCode',
                  captchaVersion: 'rv2',
                },
              });
            });
            localState.isLoading = false;
          }
        }

        if (error.code === ERROR_CODE_HAS_ALREADY_BEEN_SENT) {
          sms.codeError.value = error.message;
          localState.codeSent = true;
          if (!authorization.authIssueId.value) {
            localState.isBlockByLostAuthRequestId = true;
            sms.setTimer(CODE_TIMEOUT);
          }
          localState.isLoading = false;
        }

        throw new Error(error.message);
      });
  };

  const checkCode = async () => {
    const payload = {
      code: sms.code.value,
      id: authorization.authIssueId.value,
    };

    try {
      const res = await auth.approvePhone(payload);
      analyticAuthCorrectCode();
      return res.status;
    } catch (error) {
      if (ERROR_VERIFY_CODE.includes(error.code)) {
        sms.codeError.value = error.message;
        analyticAuthIncorrectCode();
      }
      if (error.code === ERROR_ALREADY_CONFIRM) {
        return REGISTRATION;
      }
    }
  };

  const login = async (authIssueId) => {
    const payload = {
      authIssueId: authIssueId || authorization.authIssueId.value,
    };

    if (authorization.customerId.value) {
      payload.customerId = authorization.customerId.value;
    }

    await auth.login(payload)
      .then(async () => {
        if (!authIssueId) {
          analyticAuthSuccess();
          analyticAuthFormSubmitSuccess(LOGIN);
        }

        const promoCode = await product.getCouponForUser();
        if (promoCode) {
          store.commit('setPromoCode', promoCode);
          authorization.changeModeHandler(PROMO_CODE);
        } else authorization.closeAuthorizationModal();

        authorization.updateAuthData();
        await authorization.authorizationHandler();
      })
      .catch((e) => {
        localState.isLoading = false;
        analyticAuthFormSubmitError(LOGIN, e);
        captureException(e);
      });
  };

  const checkPhoneAvailability = async (useV2Captcha = false, captchaV2) => {
    const captchaType = useV2Captcha === true ? captchaV2 : captcha.captchaOptions.value;
    const payload = {
      phone: localState.phone,
      brsign: {
        type: 'reCaptcha',
        payload: {
          token: captchaType.captchaToken,
          action: captchaType.captchaAction,
          version: captchaType.captchaVersion,
        },
      },
    };

    await auth.checkPhoneAvailability(payload)
      .then((res) => {
        localState.isAvailable = res?.isAvailable;
        localState.isBlocked = res?.isBlocked;
        localState.textError = localState.isBlocked ? USER_BLOCKED_ERROR_TEXT : '';
        localState.captchaKey = 'check-phone';
        captcha.recaptchaWidget.value = null;
      })
      .catch(async (error) => {
        if (error.code === ERROR_CAPTCHA_INVALID) {
          analyticAuthOpenCaptcha('Проверка телефона');
          localState.isShowCaptcha = true;
          if (captcha.recaptchaWidget.value !== null) await captcha.resetReCaptcha();
          if (captcha.recaptchaWidget.value === null) {
            await captcha.renderReCaptcha(async (captchaToken) => {
              await checkPhoneAvailability(true, {
                captchaToken,
                captchaAction: 'checkPhoneAvailability',
                captchaVersion: 'rv2',
              });
            });
          }
          localState.isLoading = false;
        } else localState.isLoading = false;

        throw new Error(error.message);
      });
  };

  const sendCodeByPhone = async () => {
    try {
      await captcha.generateCaptcha('sendCode');
      setTimeout(async () => {
        await sendCode();
        localState.isLoading = false;
        sms.codeLoading.value = false;
      }, 1000);
    } catch (e) {
      captureException(e);
      localState.isLoading = false;
      sms.codeLoading.value = false;
    }
  };

  const sendCodeByTinkoffID = async () => {
    try {
      await captcha.generateCaptcha('sendCode');
      await sendCode({
        strategy: 'byTinkoffId',
        byPhone: false,
      });
    } catch (e) {
      captureException(e);
    }
  };

  const submitHandler = async () => {
    analyticAuthFormButtonClick(buttonText.value);

    if (!localState.codeSent) analyticAuthRequestCode();

    localState.isLoading = true;
    sms.codeError.value = '';

    if (localState.codeSent) {
      const status = await checkCode();

      if (!status) {
        localState.isLoading = false;
        return;
      }

      if (status === REGISTRATION) {
        authorization.updateAuthData();
        authorization.changeModeHandler(REGISTRATION);
        analyticAuthRegFormOpen();
        return;
      }

      await login();
      return;
    }

    if (authorization.isAuthByTinkoffId.value) {
      if (!localState.captchaKey) {
        try {
          await captcha.generateCaptcha('checkPhoneAvailability');
          await checkPhoneAvailability();
        } catch (e) {
          captureException(e);
          return;
        }
      }

      if (!localState.isAvailable) {
        await tinkoffId.initialWidget();
        await sendCodeByTinkoffID();
        return;
      }
    }

    if (localState.isBlocked) {
      localState.isLoading = false;
      return;
    }

    await sendCodeByPhone();
  };

  const inputCodeHandler = value => sms.code.value = value;

  const resendSmsCodeHandler = async () => {
    sms.codeError.value = '';
    sms.codeLoading.value = true;
    await sendCodeByPhone();
  };

  const endCountdownHandler = () => sms.timeToResend.value = 0;

  onBeforeMount(async () => {
    if (authorization.isAuthByTinkoffId.value) return;
    if (authorization.authIssueId.value) {
      localState.isLoading = true;
      import('string-mask')
        .then(async ({ default: StringMask }) => {
          localState.phoneMask = new StringMask(PHONE_MACK);
          if (authorization.tinkoffHasError.value) {
            analyticAuthFormSubmitError(LOGIN, TINKOFF_AUTH_ERROR_TEXT);
            await checkAuth(true);
            await sendCodeByPhone();
          } else {
            await checkAuth();
            localState.isLoading = false;
          }
        });
    }
  });

  watch(() => sms.timeToResend.value,
    (newVal) => {
      if (newVal <= 0 && localState.isBlockByLostAuthRequestId) {
        localState.isBlockByLostAuthRequestId = false;
      }
    });

  watch(() => phoneIsComplete.value,
    (value) => {
      if (!value) {
        localState.codeSent = false;
        localState.isBlocked = false;
        localState.textError = '';
      }
    });

  return {
    ...toRefs(localState),
    ...captcha,
    ...sms,
    ...tinkoffId,
    buttonText,
    buttonDisabled,
    login,
    sendCodeByPhone,
    submitHandler,
    inputCodeHandler,
    resendSmsCodeHandler,
    endCountdownHandler,
  };
};

export default useLogin;
