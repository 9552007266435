// @ts-check
/// <reference path="./typedefs.js" />

import validUrl from 'valid-url';
import { deserialize } from 'deserialize-json-api';
import HttpClient from '@/utils/clients/clientBookMain.js';
import ServiceError from '@/utils/errors/ServiceError';
import { buildElement, buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedProduct from '@/utils/formatters/formattedProduct';
import {
  formattedProductCharacteristics,
  formattedAverage,
  formattedMorePicturesList,
  formattedMainPicture,
  formattedAvailableProduct,
  formattedMarksRating,
  formattedReviewPicture,
  formattedShield,
  formattedReviewsPhoto,
} from '@/utils/formatters/formattedProductDetail';
import { ERROR_MESSAGE, ERROR_NOT_FOUND } from '@/utils/constants/globalConst';
import { ERRORS_GET_PRODUCT_DETAIL } from '@/utils/constants/productDetailConstants';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { convertISOFromDateFormat, getMonthNamePrepositional } from '@/utils/dateTime';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';

export default class ProductService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получаем товар из каталога продуктов по идентификатору
   * @param {number|string} productId - идентификатор товара
   * @param {Object} [payload]
   * @param {boolean} [payload.isGetBreadcrumbs] - нужно ли получать хлебные крошки
   * @param {boolean} [payload.isGetSeoMeta] - нужно ли получать SEO данные
   * @param {boolean} [payload.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [payload.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [payload.isGetProductText=false] - Нужно ли получить текст товара
   * @param {boolean} [payload.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [payload.isGetPublishingHouse=false] - Нужно ли получить информацию об издательстве
   * @param {boolean} [payload.isGetProductImage=false] - Нужно ли получить картинки товара
   * @param {boolean} [payload.isGetProductVideo=false] - Нужно ли получить видео товара
   * @param {boolean} [payload.isGetProductAddition=false] - Нужно ли получить дополнительные данные о товаре
   * @param {boolean} [payload.isGetProductTag=false] - Нужно ли получить теги товара
   * @param {boolean} [payload.isGetProductStats=false] - Нужно ли получить теги товара
   * @param {boolean} [payload.isGetProductUserStats=false] - Нужно ли получить статистику товара
   * @param {boolean} [payload.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [payload.isGetCustomerAttraction=true] - Нужно ли получить персональный рейтинг
   * @param {boolean} [payload.isGetProductRatings=true] - Нужно ли получить список рейтингов
   * @param {boolean} [payload.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @param {boolean} [payload.referPageUrl] - Ссылка с которой был переход на страницу товара
   * @return {Promise<ProductDetail>}
   */
  getProduct(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        isGetBreadcrumbs = false,
        isGetSeoMeta = false,
        isGetAuthors = true,
        isGetBadge = true,
        isGetSeries = false,
        isGetProductText = false,
        isGetCategory = true,
        isGetPublishingHouse = false,
        isGetProductImage = false,
        isGetProductVideo = false,
        isGetProductAddition = false,
        isGetProductTag = false,
        isGetProductStats = false,
        isGetProductUserStats = false,
        isGetCustomerAttraction = true,
        isGetProductRatings = true,
        isGetOrsProduct = true,
        referPageUrl,
      } = payload;

      if (!productId) reject(new ServiceError('[ProductService:getProduct] productId обязателен для заполнения'));

      const include = [];
      if (isGetBreadcrumbs) include.push('breadcrumbs');
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetAuthors) include.push('author');
      if (isGetSeries) include.push('series');
      if (isGetProductText) include.push('productText');
      if (isGetCategory) include.push('category');
      if (isGetPublishingHouse) include.push('publishingHouse');
      if (isGetProductImage) include.push('productImage');
      if (isGetProductVideo) include.push('productVideo');
      if (isGetProductAddition) include.push('productAddition');
      if (isGetProductTag) include.push('productTag');
      if (isGetProductStats) include.push('productStats');
      if (isGetBadge) include.push('badge');
      if (isGetProductUserStats) include.push('productUserStats');
      if (isGetCustomerAttraction) include.push('customerProductAttraction');
      if (isGetProductRatings) include.push('productRating');
      if (isGetOrsProduct) include.push('orsProduct');

      const dataRequest = { include: include.join(',') };

      if (referPageUrl && validUrl.isWebUri(referPageUrl)) dataRequest.referPageUrl = referPageUrl;

      this.mainHttp.get(`/api/v1/products/${productId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          const item = formattedProduct(resFormatted);

          let badge = null;
          let badgeForShow = null;
          if (resFormatted?.relationships?.badge) {
            badge = resFormatted.relationships.badge[0] || null;
            badgeForShow = item?.badgeProductDetail || null;
          }

          let shield = null;
          if (resFormatted?.relationships?.productText?.advertisingBlockHeader
            && resFormatted?.relationships?.productText?.advertisingBlockText) {
            shield = {
              title: resFormatted.relationships.productText.advertisingBlockHeader,
              desc: resFormatted.relationships.productText.advertisingBlockText,
            };
          }

          const mainPhotoList = resFormatted.picture?.cdnType
            ? [formattedMainPicture(resFormatted.picture)]
            : [];

          const morePhotoList = resFormatted?.relationships?.productImage && !item.isAdult
            ? formattedMorePicturesList(resFormatted.relationships.productImage)
            : [];

          const productVideo = !item.isAdult ? resFormatted?.relationships?.productVideo : [];

          if (isGetSeries && Array.isArray(item.series)) {
            item.series.forEach((seriesItem) => {
              if (+seriesItem.numBooks <= 1) {
                seriesItem.link = null;
              }
            });
          }

          return resolve({
            item: {
              ...item,
              badge: badgeForShow,
              productType: resFormatted.type || null,
              productImage: [...mainPhotoList, ...morePhotoList].slice(0, 15),
              productVideo: productVideo || null,
              productAddition: resFormatted?.relationships?.productAddition || null,
              productTags: resFormatted?.relationships?.productTag || null,
              purchased: resFormatted?.relationships?.productStats?.purchased || null,
              preOrderReleaseDate: resFormatted?.preOrderReleaseDate
                ? convertISOFromDateFormat(resFormatted.preOrderReleaseDate, 'dd.MM.yyyy')
                : null,
              preOrderDate: resFormatted?.preOrderDate
                ? getMonthNamePrepositional(resFormatted.preOrderDate)
                : null,
              publishingHouse: resFormatted?.relationships?.publishingHouse || null,
              guid: resFormatted.guid,
              productShield: formattedShield({ badge, shield }),
              productUserStats: resFormatted?.relationships?.productUserStats || null,
              productText: resFormatted?.relationships?.productText || null,
              personalRating: resFormatted?.relationships?.customerProductAttraction?.rate || null,
              productRatings: resFormatted?.relationships?.productRating || null,
            },
            seoMeta: resFormatted.relationships?.seoMeta || null,
            crumbs: resFormatted.relationships?.breadcrumbs
              ? formattedPageBreadcrumbs(resFormatted.relationships.breadcrumbs)
              : [],
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data, ERRORS_GET_PRODUCT_DETAIL);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} ProductCharacteristic - характеристика товара
   * @property {string} id - идентификатор характеристики
   * @property {string} name - название характеристики
   * @property {string|null} targetUrl - ссылка на детальную страницу
   * @property {string} type - тип характеристики
   * @property {string|null} unitType - единица изменения значения
   * @property {string} value - значение характеристики
   */

  /**
   * @typedef {Object} FormattedProductCharacteristic - характеристика товара
   * @property {string} id - идентификатор характеристики
   * @property {string} name - название характеристики
   * @property {string|null} targetUrl - ссылка на детальную страницу
   * @property {string} type - тип характеристики
   * @property {string|null} unitType - единица изменения значения
   * @property {string|ProductCharacteristic[]} value - значение характеристики
   * @property {number} position - номер позиции характеристики
   */

  /**
   * Возвращает список характеристик товара по его идентификатору
   * @param {number|string} productId - идентификатор товара
   * @return {Promise<FormattedProductCharacteristic[]>}
   */
  getProductCharacteristics(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getCharacteristicProduct] productId обязателен для заполнения'));
      this.mainHttp.get(`/api/v1/product/${productId}/characteristic/`)
        .then(({ data: { data: dataRes } }) => {
          const formatted = buildCollection(dataRes);
          const list = formattedProductCharacteristics([...formatted.list]);
          resolve(list);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} ProductAvailability
   * @property {boolean} isAvailable - Доступность товар
   * @property {string} productType - Тип товар
   * @property {Object} prices - Стоимость товара
   * @property {string} prices.price - Стоимость товара с учётом скидок
   * @property {string} prices.base - Стоимость товара без скидок
   * @property {number} discountPercent - Процент скидки
   */

  /**
   * Возвращает цену, информацию о типе товара и доступности к продаже товара по его идентификатору
   * @param {number|string} productId - идентификатор товара
   * @param {Object} [payload]
   * @param {number} [payload.quantity] - кол-во экземпляров товара
   * @param {number} [payload.utmSource] - utm_source из query url
   * @param {string|null} [payload.customerId=null] - идентификатор посетителя
   * @return {Promise<ProductAvailability>}
   */
  getProductAvailability(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getProductAvailability] productId обязателен для заполнения'));

      const { quantity, utmSource, customerId = null } = payload;
      const dataRequest = {};

      if (quantity) {
        dataRequest.quantity = quantity;
      }

      if (utmSource) {
        dataRequest.utm_source = utmSource;
      }

      if (customerId) {
        dataRequest.customerId = customerId;
      }

      this.mainHttp.get(`/api/v1/product/${productId}/availability/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const formattedData = buildElement(dataRes);
          resolve(formattedAvailableProduct(formattedData));
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getProductRating(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getProductRating] productId обязателен для заполнения'));
      this.mainHttp.get(`/api/v1/product/${productId}/rating-stats/`)
        .then(({ data: { data: dataRes } }) => {
          const formattedData = buildElement(dataRes);
          resolve({
            average: formattedAverage(Number(formattedData?.average || 0)),
            averageRaw: Number(formattedData?.average || 0),
            count: formattedData?.total || 0,
            marks: formattedMarksRating(formattedData?.map || []),
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} LiveLibRating
   * @property {number} avgMark
   * @property {number} averageRaw
   * @property {string} average
   * @property {number} count
   * @property {number[]} marks
   */

  /**
   * Получаем рейтинг LiveLib товара по isbn
   * @param {string} isbn
   * @return {Promise<LiveLibRating>}
   */
  getProductLiveLibRating(isbn) {
    return new Promise((resolve, reject) => {
      if (!isbn) reject(new ServiceError('[ProductService:getProductLiveLibRating] isbn обязателен для заполнения'));
      this.mainHttp.get('/api/v1/catalog/product/rating/livelib/', {
        isbn,
      })
        .then(({ data: { data: dataRes, success } }) => {
          if (success) {
            const marks = [
              dataRes.mark1,
              dataRes.mark2,
              dataRes.mark3,
              dataRes.mark4,
              dataRes.mark5,
            ];
            return resolve({
              avgMark: dataRes.avgMark,
              average: formattedAverage(dataRes.avgMark),
              averageRaw: dataRes.avgMark,
              count: marks.reduce((accumulator, currentValue) => accumulator + currentValue),
              marks,
            });
          }
          return reject(new ServiceError(ERROR_MESSAGE));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} LiveLibReview
   * @property {number|string} id
   * @property {string} userName
   * @property {string} date
   * @property {number} bookRating
   * @property {string} review
   */

  /**
   * Получаем отзывы LiveLib по isbn товара
   * @param {string} isbn
   * @return {Promise<LiveLibReview[]>}
   */
  getProductLiveLibReviews(isbn) {
    return new Promise((resolve, reject) => {
      if (!isbn) reject(new ServiceError('[ProductService:getProductLiveLibReviews] isbn обязателен для заполнения'));
      this.mainHttp.get('/api/v1/catalog/product/reviews/livelib/', {
        isbn,
      })
        .then(({ data: { data: dataRes, success } }) => {
          if (success && dataRes && Array.isArray(dataRes)) {
            return resolve(dataRes.map(item => ({
              id: item.id,
              userName: item.login,
              date: item.date ? item.date.split('.,')[0] : '',
              bookRating: item.bookRating,
              review: item.review,
            })));
          }
          return reject(new ServiceError(ERROR_MESSAGE));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} ProductReviewItem
   * @property {number|string} id
   * @property {string} userName
   * @property {Date|null} date
   * @property {number} bookRating
   * @property {string} review
   * @property {number} dislikeCount
   * @property {number} likeCount
   * @property {Array} imagesList
   * @property {boolean} isPurchased
   */

  /**
   * @typedef {Object} Pagination
   * @property {number} currentPage
   * @property {number} totalPages
   * @property {number} perPage
   * @property {number} total
   * @property {number} count
   */

  /**
   * @typedef {Object} PayloadProductReview
   * @property {number} [pageNumber=1]
   * @property {number} [perPage=5]
   * @property {string} [sorting='new']
   * @property {boolean} [isSelf=false]
   * @property {boolean} [isGetProductReviewUserStats=false]
   * @property {boolean} [isGetProductReviewCurrentUserStat=false]
   */

  /**
   * @typedef {Object} ProductReview
   * @property {ProductReviewItem[]} list
   * @property {Pagination} pagination
   */

  /**
   * Возвращает отзывы к товару
   * @param {number|string} productId
   * @param {PayloadProductReview} [payload]
   * @return {Promise<ProductReview>}
   */
  getProductReview(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getProductReview] productId обязателен для заполнения'));
      const {
        pageNumber = 1,
        perPage = 5,
        sorting = 'new',
        isSelf = false,
        isGetProductReviewUserStats = false,
        isGetProductReviewCurrentUserStat = false,
      } = payload;

      const dataRequest = {
        'per-page': perPage,
        page: pageNumber,
        sorting,
      };

      const includes = [];
      if (isGetProductReviewUserStats) includes.push('productReviewUserStats');
      if (isGetProductReviewCurrentUserStat) includes.push('productReviewCurrentCustomerStats');
      if (includes.length) {
        dataRequest.include = includes.join(',');
      }

      if (isSelf) {
        dataRequest.self = isSelf;
      }

      this.mainHttp.get(`/api/v1/product/${productId}/review/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { pagination, list } = buildCollection(dataRes, true);
          return resolve({
            list: list.map(item => ({
              id: item.id,
              userName: [item.user.name, item.user.lastName].filter(i => i).join(' ').trim(),
              userId: item.user.id,
              date: item.createdAt,
              bookRating: item.rate,
              review: item.comment,
              likeCount: item.likeCount,
              dislikeCount: item.dislikeCount,
              imagesList: formattedReviewPicture(item.imagesList),
              isPurchased: Boolean(item?.relationships?.productReviewUserStats?.isPurchased),
              voteType: item?.relationships?.productReviewCurrentCustomerStats?.vote,
            })),
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} AddedVoteReview
   * @property {string|number} id
   * @property {number} likeCount
   * @property {number} dislikeCount
   */

  /**
   * Добавление лайка/дизлайка отзыву товара
   * @param {number|string} reviewId
   * @param {Object} [options]
   * @param {string} [options.mode='like'] - Тип оценки (like, dislike)
   * @return {Promise<AddedVoteReview>}
   */
  addVoteReview(reviewId, options = {}) {
    return new Promise((resolve, reject) => {
      if (!reviewId) reject(new ServiceError('[ProductService:addVoteReview] reviewId обязателен для заполнения'));

      const { mode = 'like' } = options;

      this.mainHttp.post(`/api/v1/review/${reviewId}/vote/`, { mode })
        .then(({ data: { data: dataRes } }) => {
          /** @type {AddedVoteReview} */
          const item = buildElement(dataRes);
          return resolve(item);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получение списка товаров
   * @param {Object} data
   * @param {string} [data.match=''] - Фильтр match для сфинкса
   * @param {string} [data.filterPreset=''] - Заготовленный пресет сортировки
   * @param {string} [data.filter=''] - Фильтр
   * @param {number | undefined} [data.shopId=undefined] - Id магазина
   * @param {string} [data.sortPreset=''] - Заготовленный пресет сортировки
   * @param {number} [data.pageSize=15] - Количество товаров на странице
   * @param {number} [data.pageNumber=1] - Номер страницы в пагинации
   * @param {boolean} [data.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [data.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [data.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [data.isGetProductText=false] - Нужно ли получить описание товара
   * @param {string} [data.getQuotes=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @param {boolean} [data.isGetPublishingHouse=false] - Нужно ли получить издателя товара
   * @param {boolean} [data.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [data.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @param {boolean} [data.isGetProductStats=false] - Нужно ли получать статистику по товару
   * @param {boolean} [data.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @return {Promise<ProductList|Error|ErrorObject>}
   */
  getProducts(data = {}) {
    const {
      match = '',
      filterPreset = '',
      filter = '',
      sortPreset = '',
      pageSize = 15,
      pageNumber = 1,
      getQuotes = '',
      isGetAuthors = true,
      isGetSeries = false,
      isGetBadge = true,
      isGetProductText = false,
      isGetPublishingHouse = false,
      isGetCategory = true,
      isGetParentCategory = false,
      isGetProductStats = false,
      isGetOrsProduct = true,
      shopId = undefined,
    } = data;

    const include = [];
    if (isGetAuthors) include.push('author');
    if (isGetSeries) include.push('series');
    if (isGetBadge) include.push('badge');
    if (isGetProductText) include.push('productText');
    if (getQuotes) include.push(getQuotes);
    if (isGetPublishingHouse) include.push('publishingHouse');
    if (isGetCategory) include.push('category');
    if (isGetParentCategory) include.push('category.parentCategory');
    if (isGetProductStats) include.push('productStats');
    if (isGetOrsProduct) include.push('orsProduct');
    if (shopId) include.push('orsPartnerProduct');

    let dataRequest = { 'per-page': pageSize, page: pageNumber };

    if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
    if (shopId) dataRequest = { ...dataRequest, shopId };
    if (filter) dataRequest = { ...dataRequest, filter };
    if (sortPreset) dataRequest = { ...dataRequest, sortPreset };
    if (match) dataRequest = { ...dataRequest, match };
    if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/products/', dataRequest)
      .then(({ data: { data: dataRes } }) => {
        const { list, pagination } = buildCollection(dataRes, true);
        const items = list.map((item) => {
          const itemForFormat = item;
          if (shopId) {
            itemForFormat.amount = { discounted: item.relationships.orsPartnerProduct.amount };
          }
          const element = formattedProduct(itemForFormat);
          if (isGetProductText) {
            element.productText = {
              advertisingBlockHeader: item.relationships.productText.advertisingBlockHeader,
              advertisingBlockText: item.relationships.productText.advertisingBlockText,
              detailDescription: item.relationships.productText.detailDescription,
              additionalDescription: item.relationships.productText.additionalDescription,
              showAdditionalDescription: item.relationships.productText.showAdditionalDescription,
            };
          }

          if (isGetProductStats) {
            element.productStats = {
              purchased: item.relationships.productStats.purchased,
              reviewTotal: item.relationships.productStats.reviewTotal,
            };
          }

          if (isGetSeries && Array.isArray(item.series)) {
            element.series.forEach((seriesItem) => {
              if (+seriesItem.numBooks <= 1) {
                seriesItem.link = null;
              }
            });
          }

          return element;
        }) || [];
        return resolve({
          items,
          pagination,
        });
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Добавляем отзыв к товару
   * @param {PayloadReviewProduct} payload
   * @return {Promise<unknown>}
   */
  addReviewProduct(payload) {
    return new Promise((resolve, reject) => {
      const { configuration, text } = payload;
      if (!configuration.productId) reject(new ServiceError('[ProductService:addReviewProduct] productId обязателен для заполнения'));

      if (typeof text !== 'string' || text.length === 0) {
        reject(new ServiceError('[ProductService:addReviewProduct] Не заполнено поле отзыва'));
      }

      this.mainHttp.post('/api/v1/review/', payload)
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получение информации о персональной лояльности
   * @param {number|string} productId - идентификатор товара
   * @param {Object} payload
   * @param {number} payload.priceWithDiscount - цена товара
   * @param {number} [payload.isRecommendation=0] - является ли товар рекомендованным для пользователя
   * @returns {Promise<unknown>}
   */
  getProductPersonal(productId, { priceWithDiscount, isRecommendation = 0 }) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getProductPersonal] productId обязателен для заполнения'));
      if (!priceWithDiscount) reject(new ServiceError('[ProductService:priceWithDiscount] productId обязателен для заполнения'));
      if (typeof priceWithDiscount !== 'number' && priceWithDiscount <= 0) reject(new ServiceError('[ProductService:priceWithDiscount] productId должен быть положительным числом'));

      const dataRequest = {
        priceWithDiscount,
        isRecommendation,
      };

      this.mainHttp.get(`/api/v1/products/${productId}/personal/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          const { earnedBonus } = data;
          resolve(Math.round(earnedBonus));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getCouponForUser() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/coupon-for-user/?type=forNewUser')
      .then(({ data: { data: dataRes } }) => {
        if (!dataRes?.length) resolve(false);
        resolve(dataRes[0].attributes);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }

  getCouponForNotAvailableProduct() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/coupon-for-not-available-product/')
      .then(({ data: { data: dataRes } }) => resolve(buildElement(dataRes)))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }

  getProductPeopleRecommended(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getProductPeopleRecommended] productId обязателен для заполнения'));
      }
      this.mainHttp.get(`/api/v1/product-people-recommended/${productId}/`)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          return resolve(list.map(item => ({
            ...item,
            photo: formattedPicturesCdnBook24(item.photo.url, {
              sizePicWidth: 40,
              sizePicHeight: 40,
            }),
            link: item.code && item.id ? `/people-and-books/${item.code}-${item.id}/` : null,
          })));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получаем список рекомендуемых издательством товаров
   * @param {string} [productId] - ID товара
   * @return {Promise<unknown>}
   */
  getProductsPublisherRecommended(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService: getProductsPublisherRecommended] productId обязателен для заполнения'));
      }

      this.mainHttp.get(`/api/v1/product-publisher-recommended/${productId}/`)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({ items });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getProductAlsoPurchased(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getProductAlsoPurchased] productId обязателен для заполнения'));
      }

      const include = 'author,series,productText,badge,publishingHouse,category,orsProduct';

      this.mainHttp.get(`/api/v1/product-also-purchased/${productId}/?limit=3&include=${include}`)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          return resolve(list.map(item => formattedProduct(item)));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getProductAwards(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getProductAwards] productId обязателен для заполнения'));
      }

      this.mainHttp.get(`/api/v1/product/${productId}/award/`)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          return resolve(list);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} PayloadProductQuotes
   * @property {number} [pageNumber=1]
   * @property {number} [perPage=5]
   */

  /**
   * Получаем цитаты товара
   * @param {string|number} productId
   * @param {PayloadProductQuotes} payload
   * @return {Promise<unknown>}
   */
  getProductQuotes(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getProductQuote] productId обязателен для заполнения'));
      }

      const { pageNumber = 1, perPage = 5 } = payload;

      this.mainHttp.get(`/api/v1/products/${productId}/quote/`, {
        page: pageNumber,
        'per-page': perPage,
      })
        .then(({ data: { data: dataRes } }) => {
          const collection = buildCollection(dataRes, true);
          return resolve(collection);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getProductAnswers(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getProductAnswers] productId обязателен для заполнения'));
      }

      this.mainHttp.get(`/api/v1/product/${productId}/answer/`)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          return resolve(list);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получаем фотографии загруженные пользователями при добавлении отзывов
   * @param {number|string} productId - идентификатор товара
   * @returns {Promise<unknown>}
   */
  getReviewsPhotos(productId) {
    return new Promise((resolve, reject) => {
      if (!productId) reject(new ServiceError('[ProductService:getReviewsPhotos] productId обязателен для заполнения'));
      this.mainHttp.get(`/api/v1/product/${productId}/review-photo/`)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedReviewsPhoto(data));
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получаем информацию о доставках
   * @param {number|string} productId
   * @param {Object} payload
   * @param {boolean} [payload.isPreOrder=false]
   * @param {boolean} [payload.isAvailable=false]
   * @param {string} [payload.coordinates]
   * @param {string|null} [payload.customerId=null]
   * @param {string} [payload.locationId]
   * @returns {Promise<unknown>}
   */
  getDeliveryInfoTmp(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!productId) {
        return reject(new ServiceError('[ProductService:getDeliveryInfo] productId обязателен для заполнения'));
      }

      const {
        isPreOrder = false,
        isAvailable = true,
        coordinates,
        customerId = null,
        locationId,
      } = payload;

      const dataRequest = {
        productId,
        preOrder: isPreOrder ? 1 : 0,
        available: isAvailable ? 1 : 0,
        customerId,
        locationId,
      };

      if (coordinates) {
        dataRequest.coordinates = coordinates;
      }

      if (!customerId) {
        dataRequest.customerId = null;
      }

      this.mainHttp.get('/api/v1/delivery-info-tmp/', dataRequest)
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
