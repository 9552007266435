import {
  computed,
  inject,
  provide,
  reactive,
  ref,
  toRefs,
} from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';
import { useLocalStorage } from '@vueuse/core';

import { ERRORS_BASKET } from '@/services/v1/BasketService/enumsError';
import { LOCAL_CONST_BASKET, LOCAL_CONST_CUSTOMERS } from '@/utils/constants/globalConst';
import { getVisitorId } from '@/utils/visitorId';
import { getPropertiesFromAttributes } from '@/utils/analytics/getPropertiesFromAttributes';
import { setTargetProperties } from '@/utils/analytics/setTargetProperties';
import { getHowMuchQuantityChanged } from '@/utils/analytics/getHowMuchQuantityChanged';

import { useAnalyticProducts } from '@/compositions/analytics/useAnalyticProducts';
import useCommonModal from '@/compositions/useCommonModal';
import useHttpClient from '@/compositions/useHttpClient';

const BASKET_CONTEXT = Symbol('b24-basket');
const COUNT_ERROR = 3;
export const AMOUNT_DEFAULT_VALUE = {
  discounted: 0,
  total: 0,
};
export const DISCOUNT_AMOUNT_DEFAULT_VALUE = {
  percent: 0,
  total: 0,
};

export const useBasketProvider = () => {
  const { customers, basket } = useHttpClient();
  const { openError } = useCommonModal();

  const firstRender = ref(true);

  const countAttemptErrorCustomer = ref(COUNT_ERROR);

  const isLoadingFetchBasket = ref(false);

  const customerId = useLocalStorage(LOCAL_CONST_CUSTOMERS, null);

  const basketId = useLocalStorage(LOCAL_CONST_BASKET, null);

  const analyticProducts = useAnalyticProducts();

  const state = reactive({
    id: null,
    amount: { ...AMOUNT_DEFAULT_VALUE },
    discountAmount: { ...DISCOUNT_AMOUNT_DEFAULT_VALUE },
    quantity: 0,
    weight: 0,
    basketItem: [],
    basketPromoCode: null,
    isBasketCreating: false,
    basketItemsCount: 0,
  });

  let onBasketCreateSubscribersList = [];

  const productIdList = computed(() => state.basketItem.map(item => +item.product.id));

  const counterSmallBasket = computed(() => {
    if (state.quantity > 99) {
      return '99+';
    }
    return state.quantity;
  });

  const resetData = () => {
    customerId.value = '';
    basketId.value = '';
    state.id = null;
    state.amount = { ...AMOUNT_DEFAULT_VALUE };
    state.discountAmount = { ...DISCOUNT_AMOUNT_DEFAULT_VALUE };
    state.quantity = 0;
    state.weight = 0;
    state.basketItem = [];
    state.isBasketCreating = false;
  };

  const createCustomers = async () => {
    const visitorId = await getVisitorId();
    return customers.createCustomers({ visitorId })
      .then((data) => {
        customerId.value = data.id;
      })
      .catch((err) => {
        customerId.value = '';
        state.isBasketCreating = false;
        throw err;
      });
  };

  const createBasket = id => basket.createBasket(id)
    .then((data) => {
      basketId.value = data.id;
    })
    .catch((err) => {
      basketId.value = '';
      state.isBasketCreating = false;
      throw err;
    });

  const createCustomersAndBasket = async (force = false) => {
    state.isBasketCreating = true;
    if (!customerId.value || force) {
      await createCustomers();
      basketId.value = '';
    }
    if (!basketId.value || force) {
      await createBasket(customerId.value);
      if (!force) {
        onBasketCreateSubscribersList.forEach(item => item());
        onBasketCreateSubscribersList = [];
      }
    }
    state.isBasketCreating = false;
  };

  const fixLegacyCustomers = async () => {
    await customers.fixLegacyCustomers(customerId.value)
      .then(async (data) => {
        customerId.value = data.id;
        await createBasket();
      })
      .catch((err) => {
        customerId.value = '';
        throw err;
      });
  };

  const checkErr = async (err, callback) => {
    try {
      const errorCode = err?.code;
      if (countAttemptErrorCustomer.value > 0) {
        countAttemptErrorCustomer.value -= 1;

        if (ERRORS_BASKET.isErrorLegacy(errorCode)) {
          await fixLegacyCustomers();
        } else if (ERRORS_BASKET.isNotExistCustomer(errorCode) || ERRORS_BASKET.isNotExistBasket(errorCode)) {
          customerId.value = '';
          await createCustomersAndBasket();
        }

        if (callback && typeof callback === 'function') {
          return await callback();
        }
      } else {
        countAttemptErrorCustomer.value = COUNT_ERROR;
        openError(err);
        captureException(err);
      }
    } catch (error) {
      return checkErr(error, () => callback());
    }
  };

  const setDataBasket = (dataRes) => {
    state.quantity = dataRes.quantity;
    state.weight = dataRes.weight;
    state.id = dataRes.id;
    state.amount = dataRes.amount;
    state.discountAmount = dataRes.discountAmount;
    state.basketItem = dataRes.basketItem;
    state.basketPromoCode = dataRes.basketPromoCode;
    state.basketItemsCount = dataRes.basketItem.length;
  };

  const fetchBasket = async (options = {}) => {
    const { isPreOrder = false, isSetData = true } = options;
    isLoadingFetchBasket.value = true;
    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => fetchBasket(options));
        return;
      }
      await createCustomersAndBasket();

      const dataRes = await basket.fetchBasket(basketId.value, {
        isPreOrder,
        isGetBasketPromoCode: true,
        isGetBasketItem: true,
        isGetBasketItemProduct: true,
      });

      if (isSetData) {
        setDataBasket(dataRes);

        if (firstRender.value) {
          firstRender.value = false;
        }
      }

      return dataRes;
    } catch (err) {
      return checkErr(err, () => fetchBasket(options));
    } finally {
      isLoadingFetchBasket.value = false;
    }
  };

  const fetchSmallBasket = async (options = {}) => {
    const { isSetData = true, isGetBasketItem = false } = options;
    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => fetchSmallBasket(options));
        return;
      }
      await createCustomersAndBasket();

      const dataRes = await basket.fetchSmallBasket(basketId.value, { isGetBasketItem });

      if (isSetData) {
        state.quantity = dataRes.count;
      }

      return dataRes;
    } catch (err) {
      return checkErr(err, () => fetchSmallBasket(options));
    }
  };

  const syncBasket = async (options = {}) => {
    const {
      isSetData = true,
      isPreOrder = false,
      isAddFirstGift = true,
    } = options;
    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => syncBasket(options));
        return;
      }
      await createCustomersAndBasket();

      const dataRes = await basket.syncBasket(basketId.value, {
        isGetBasketItem: true,
        isGetBasketItemProduct: true,
        isPreOrder,
        isAddFirstGift,
        isGetBasketPromoCode: true,
      });

      if (isSetData) {
        setDataBasket(dataRes);

        if (firstRender.value) {
          firstRender.value = false;
        }
      }

      return dataRes;
    } catch (err) {
      return checkErr(err, () => syncBasket(options));
    }
  };

  const addToBasket = async (options = {}) => {
    const {
      productId,
      quantity = 1,
      asGift = false,
      asPreOrder = false,
      isFetchBasket = true,
      target,
    } = options;

    const product = Object.assign(
      getPropertiesFromAttributes(target),
      { productQuantity: quantity },
    );

    const targetProperties = setTargetProperties(product);

    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => addToBasket(options));
        return;
      }
      await createCustomersAndBasket();

      await basket.addToBasket({
        productId,
        quantity,
        asGift,
        asPreOrder,
        basketId: basketId.value,
        targetProperties,
      });

      if (isFetchBasket) {
        await fetchBasket();
      }
    } catch (err) {
      return checkErr(err, () => addToBasket(options));
    } finally {
      analyticProducts.sendAnalyticAddCart([product]);
    }
  };

  const deleteBasketItem = async (options = {}) => {
    const {
      itemId,
      isFetchBasket = true,
      target,
      quantity,
    } = options;

    const product = Object.assign(
      getPropertiesFromAttributes(target),
      { productQuantity: quantity },
    );

    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => deleteBasketItem(options));
        return;
      }
      await createCustomersAndBasket();

      await basket.deleteBasketItem({
        itemId,
        basketId: basketId.value,
      });

      state.basketItemsCount -= 1;

      if (isFetchBasket) {
        await fetchBasket();
      }
    } catch (error) {
      return checkErr(error, () => deleteBasketItem(options));
    } finally {
      analyticProducts.sendAnalyticRemoveCart([product]);
    }
  };

  const updateBasketItem = async (options = {}) => {
    const {
      itemId,
      isFetchBasket = true,
      quantity = 1,
      target,
      oldQuantity,
    } = options;

    const quantityChanged = getHowMuchQuantityChanged(oldQuantity, quantity);

    const product = Object.assign(
      getPropertiesFromAttributes(target),
      { productQuantity: quantityChanged.quantity },
    );

    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => updateBasketItem(options));
        return;
      }
      await createCustomersAndBasket();

      await basket.updateBasketItem({
        itemId,
        quantity,
        basketId: basketId.value,
      });

      if (isFetchBasket) {
        await fetchBasket();
      }
    } catch (error) {
      return checkErr(error, () => deleteBasketItem(options));
    } finally {
      if (target) {
        if (quantityChanged.isAdded) analyticProducts.sendAnalyticAddCart([product]);
        else analyticProducts.sendAnalyticRemoveCart([product]);
      }
    }
  };

  const addToBasketItems = async (options) => {
    const {
      productItems,
      isFetchBasket = true,
      analyticItems,
    } = options;
    try {
      if (state.isBasketCreating) {
        onBasketCreateSubscribersList.push(() => addToBasketItems(options));
        return;
      }
      await createCustomersAndBasket();

      await basket.addToBasketItems({
        basketId: basketId.value,
        items: productItems,
      });

      if (isFetchBasket) await fetchBasket();
    } catch (err) {
      return checkErr(err, () => addToBasketItems(options));
    } finally {
      analyticProducts.sendAnalyticAddCart(analyticItems);
    }
  };

  const deleteBasketItems = async (options = {}) => {
    const {
      basketIdItems,
      isFetchBasket = true,
      analyticItems,
    } = options;

    try {
      await basket.deleteBasketItems({
        basketId: basketId.value,
        basketIdItems,
      });

      state.basketItemsCount = 0;

      if (isFetchBasket) await fetchBasket();
    } catch (err) {
      return checkErr(err, () => deleteBasketItems(options));
    } finally {
      analyticProducts.sendAnalyticRemoveCart(analyticItems);
    }
  };

  const applyPromoCode = (promoCode = '') => new Promise((resolve, reject) => {
    basket.applyPromoCode({ basketId: basketId.value, promoCode })
      .then((dataRes) => {
        resolve(dataRes.basketPromoCode);
      })
      .catch((err) => {
        reject(err);
      });
  });

  const removePromoCode = (promoCode = '') => new Promise((resolve, reject) => {
    basket.removePromoCode({ basketId: basketId.value, promoCode })
      .then((dataRes) => {
        resolve(dataRes.basketPromoCode);
      })
      .catch((err) => {
        reject(err);
      });
  });

  provide(BASKET_CONTEXT, {
    ...toRefs(state),
    firstRender,
    customerId,
    basketId,
    productIdList,
    counterSmallBasket,
    isLoadingFetchBasket,
    resetData,
    createCustomers,
    createBasket,
    fetchSmallBasket,
    fetchBasket,
    syncBasket,
    addToBasket,
    deleteBasketItem,
    updateBasketItem,
    addToBasketItems,
    deleteBasketItems,
    applyPromoCode,
    removePromoCode,
    createCustomersAndBasket,
  });
};

export const useBasketContext = () => {
  const context = inject(BASKET_CONTEXT);

  if (!context) {
    throw new Error('useBasketContext необходимо использовать с useBasketProvider');
  }

  return context;
};
