<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-login"
  >
    <div class="authorization-login__inner">
      <h3 class="authorization-login__title">
        Вход и регистрация
      </h3>

      <p class="authorization-login__desc">
        Чтобы войти или зарегистрироваться,
        укажите ваш номер телефона.
      </p>

      <form
        id="authorization-login"
        @submit.prevent="submitHandler"
      >
        <form-item
          :is-gray-label="true"
          :class="{ '_no-mb': codeSent }"
          :text-error="textError"
          class="authorization-login__input"
          label="Телефон"
        >
          <input-select-control
            v-model="phone"
            :is-phone="true"
            :items="countries"
            :is-hide-options="true"
            :is-disabled="isLoading"
            selected-option="ru"
            tabindex="1"
            name="phone"
            autocomplete="on"
          />
        </form-item>

        <sms-code
          v-if="codeSent"
          :loading="codeLoading"
          :time-to-resend="timeToResend"
          :code-error="codeError"
          tabindex="2"
          class="authorization-login__sms-code"
          @onInputCode="inputCodeHandler"
          @onResendSmsCode="resendSmsCodeHandler"
          @onEndCountdown="endCountdownHandler"
        />

        <div
          v-show="isShowCaptcha"
          :key="captchaKey"
          ref="recaptchaContainer"
          :class="{'b24-form-item': recaptchaWidget !== null}"
          class="authorization-login__input"
        ></div>

        <button-control
          size="large"
          native-type="submit"
          :tabindex="codeSent ? 3 : 2"
          :is-block="true"
          :loading="isLoading"
          :disabled="buttonDisabled"
          :is-added-disabled-class-name="buttonDisabled"
          :class="{ '_no-mb': codeSent }"
          class="authorization-login__button"
        >
          {{ buttonText }}
        </button-control>

        <p
          v-if="!codeSent"
          class="authorization-login__policy"
        >
          Нажимая кнопку «Получить код», вы даёте
          <app-link
            href="/terms/soglasie-pd/"
            target="_blank"
          >
            согласие на обработку
          </app-link> своих персональных данных
          в соответствии с Политикой обработки ПД
        </p>
      </form>
      <form
        v-show="false"
        id="form-tinkoff-id"
        @submit.prevent="sendCodeByPhone"
      >
        <input ref="phoneInput" v-model="phone" />
        <button ref="submitButton" type="submit"></button>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { FormItem, InputSelectControl, ButtonControl } from '@book24/ui-components';

import { PHONE_COUNTRIES } from '@/utils/constants/authorizationConstants';

import useLogin from '@/compositions/authorization/useLogin';

import SmsCode from '@/components/Authorization/SmsCode/index.vue';

export default defineComponent({
  name: 'AuthorizationLogin',
  components: {
    FormItem,
    InputSelectControl,
    ButtonControl,
    SmsCode,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const login = useLogin(props, { emit });

    return {
      ...login,
      countries: PHONE_COUNTRIES,
    };
  },
});
</script>

<style lang="less">
.authorization-login {
  &__title {
    .h3-desktop;

    margin-top: 0;
    margin-bottom: @size-x3;
  }

  &__desc {
    .p2;

    margin-top: 0;
    margin-bottom: @size-x5;
  }

  &__policy {
    .small2;

    margin: 0;
    color: @gray-dark;
  }

  &__input.b24-form-item {
    margin-bottom: @size-x5;
    padding: 0 !important;
  }

  &__input .b24-form-item__desc._before {
    position: relative;
  }

  &__sms-code {
    margin: @size-x5 0;
  }

  &__button {
    margin-bottom: @size-x3;

    &._no-mb {
      margin-bottom: 0;
    }
  }

  &._mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  &._mobile & {
    &__desc {
      .small;
    }
  }
}
</style>
