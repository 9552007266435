// @ts-check
/// <reference path="./typedefs.js" />

import { deserialize } from 'deserialize-json-api';
import camelCase from 'lodash.camelcase';

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import {
  formattedPvz,
} from '@/services/v1/CheckoutService/formattedPvz';
import { ERROR_SERVER_ERROR } from '@/utils/constants/globalConst';
import {
  ERROR_CERTIFICATE,
  ERRORS_DELIVERY,
  ERRORS_DELIVERY_SUMMARY,
  ERRORS_ORDER,
  ERRORS_PAYMENT_SYSTEMS,
  ERRORS_SUMMARY,
} from '@/services/v1/CheckoutService/enums';
import { useOrderPropertyValue } from '@/compositions/useOrderPropertyValue';
import formatDate from '@/utils/formatDate';
import { priceFormatted } from '@/utils/formatters/formattedPrice';
import { getFormatDateTime } from '@/utils/dateTime';
import { PAY_SYSTEMS_TYPE } from '@/modules/checkout/enums';
import { ERRORS_BASKET } from '@/services/v1/BasketService/enumsError';

const formatTimeInterval = (interval) => {
  const splitInterval = interval.split('-').map(item => `${item}:00`);
  return splitInterval.join(' - ');
};

const getLegalPaySystemId = paySystemId => (
  paySystemId === PAY_SYSTEMS_TYPE.cloudpayments_api.id ? PAY_SYSTEMS_TYPE.beznal.id : paySystemId
);

const getRightType = isLegal => (isLegal ? 'legal' : 'individual');

const typeDiscounts = Object.freeze({
  calculationsTypeForPaymentCard: 'forPayment',
  calculationsTypeForDeliveryWhenPayingOnline: 'forDeliveryWhenPayingOnline',
  discountsTypeBasketItem: 'basketItem',
  discountsTypeDelivery: 'delivery',
});

const valueReplace = value => value.replace(/-/g, '');

/**
 * Возвращает скидку по оплате картой
 * @param {Array} discountCalculations - все возможные скидки
 * @returns {number}
 */
const getDiscountForPaymentCard = (discountCalculations) => {
  let discountForPaymentCard = 0;
  const basketItems = discountCalculations
    .filter(item => item.type === typeDiscounts.discountsTypeBasketItem) || [];

  basketItems.forEach((item) => {
    const calculationsItem = item.calculations
      .filter(itemCalc => itemCalc.discount.type === typeDiscounts.calculationsTypeForPaymentCard)
      || [];
    calculationsItem.forEach((itemCalc) => {
      discountForPaymentCard += itemCalc.amount * item.attributes.quantity || 0;
    });
  });

  return discountForPaymentCard;
};

/**
 * Возвращает скидку по доставке за оплату картой
 * @param {Array} discountCalculations - все возможные скидки
 * @returns {number}
 */
const getDiscountForDeliveryWhenPayingOnline = (discountCalculations) => {
  let discountForDeliveryWhenPayingOnline = 0;

  const itemsDiscountForDeliveryWhenPayingOnline = discountCalculations
    .filter(item => item.type === typeDiscounts.discountsTypeDelivery) || [];

  itemsDiscountForDeliveryWhenPayingOnline.forEach((item) => {
    const calculationsItem = item?.calculations
      .filter(itemCalc => itemCalc.discount.type === typeDiscounts.calculationsTypeForDeliveryWhenPayingOnline)
      || [];
    calculationsItem.forEach((itemCalc) => {
      const amount = itemCalc?.amount ? Number(itemCalc?.amount) : 0;
      discountForDeliveryWhenPayingOnline += amount || 0;
    });
  });

  return discountForDeliveryWhenPayingOnline;
};

/**
 * Возвращает скидки по типам
 * @param {Object} params
 * @param {Array} params.discountCalculations - все возможные скидки
 * @param {number} params.discountPriceAll - сумма общей скидки
 * @param {Array<string>} params.typesOfDiscounts - типы скидок которые хотим получить
 * @returns {{discountOther: number}}
 */
export const getDiscounts = (params) => {
  const {
    discountCalculations = [],
    discountPriceAll = 0,
    typesOfDiscounts = [],
  } = params;
  const discounts = {
    discountOther: discountPriceAll,
  };

  typesOfDiscounts.forEach((item) => {
    if (item === typeDiscounts.calculationsTypeForPaymentCard) {
      discounts.discountForPaymentCard = getDiscountForPaymentCard(discountCalculations);
      discounts.discountOther -= discounts.discountForPaymentCard;
    }

    if (item === typeDiscounts.calculationsTypeForDeliveryWhenPayingOnline) {
      discounts.discountForDeliveryWhenPayingOnline = getDiscountForDeliveryWhenPayingOnline(discountCalculations);
    }
  });

  return discounts;
};

const checkDefaultSummery = (data, reject) => {
  const isStrategyBasket = data.strategy === 'basket';
  const isStrategyProduct = data.strategy === 'product';

  if (!isStrategyBasket && !isStrategyProduct) {
    return reject(new ServiceError(ERRORS_DELIVERY_SUMMARY.incorrectStrategy));
  }

  if (isStrategyProduct && !data.productId && typeof data.productId !== 'number') {
    return reject(new ServiceError(ERRORS_DELIVERY_SUMMARY.incorrectProductId));
  }

  if (isStrategyProduct && !data.quantity && typeof data.quantity !== 'number') {
    return reject(new ServiceError(ERRORS_DELIVERY_SUMMARY.incorrectQuantity));
  }

  if (isStrategyProduct && data.coupon && typeof data.coupon !== 'string') {
    return reject(new ServiceError(ERRORS_DELIVERY_SUMMARY.incorrectCoupon));
  }

  if (isStrategyBasket && data.basketId && typeof data.basketId !== 'string') {
    return reject(new ServiceError(ERRORS_DELIVERY_SUMMARY.incorrectBasketId));
  }
};

const checkStrategy = (strategy) => {
  const isStrategyBasket = strategy === 'basket';
  const isStrategyProduct = strategy === 'product';

  return { isStrategyBasket, isStrategyProduct };
};

export default class CheckoutService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * @param {PayloadGetPvzByCode} payload
   * @return {Promise<unknown>}
   */
  getPvzList(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.location) reject(new ServiceError('[CheckoutService:getPvzList] location обязателен для заполнения'));

      const dataRequest = {
        shipment: {
          location: {
            code: payload.location,
            distance: payload.distance ?? null,
            coordinates: null,
          },
          filter: {
            just: payload.just ?? null,
            id: payload.filterId ?? null,
          },
          dimensions: payload.dimensions,
          delivery: {
            tariffCodes: payload.deliveryMethods,
          },
          basket: {
            promoCode: payload.promoCode,
            totalAmount: String(payload.totalAmount),
            discountedAmount: String(payload.discountedAmount),
          },
        },
      };

      if (payload.coordinates && Array.isArray(payload.coordinates) && payload.coordinates.length) {
        const [latitude, longitude] = payload.coordinates;
        dataRequest.shipment.location.coordinates = { latitude: String(latitude), longitude: String(longitude) };
      }

      return this.mainHttp.post('/api/v1/checkout/points/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);

          return resolve({
            list: data.map(point => formattedPvz(point, !payload.isPreOrder)),
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Возвращает список станций метро
   * @param {PayloadGetMetro} payload
   * @returns {Promise<Object<string, ItemMetro>>}
   */
  getMetro({ location }) {
    return new Promise((resolve, reject) => {
      if (!location) reject(new ServiceError('[CheckoutService:getMetro] location обязателен для заполнения'));

      return this.mainHttp.get('/api/v1/sale/metro/', {
        LOCATION: location,
      })
        .then(({ data: { data: dataRes } }) => {
          const { metroStations } = dataRes;
          const formattedStations = metroStations.reduce((stations, station) => {
            stations[station.id] = {
              name: station.name,
              coordinates: [station.latitude, station.longitude],
              metroId: station.id,
              colorBranch: station.color,
            };

            return stations;
          }, {});

          return resolve(formattedStations);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} PayloadGetOrderDeliveries
   * @param {string} payload.locationCode
   * @param {string} [payload.zip]
   * @param {('product' | 'basket')} [payload.strategy='basket']
   * @param {number} [payload.productId]
   * @param {number} [payload.quantity=1]
   * @param {string} [payload.coupon]
   * @param {string} [payload.basketId]
   * @param {string} [payload.isTmpEndpoint]
   * @return {Promise<PreviousOrder>}
   */

  /**
   * @param {PayloadGetOrderDeliveries} data
   * @return {Promise<unknown>}
   */
  getOrderDeliveries({
    locationCode,
    zip,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
    isLegal = false,
    paySystemId = 31,
    isTmpEndpoint = false,
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const legalPaySystemId = getLegalPaySystemId(paySystemId);

      const dataRequest = {
        from: {
          strategy,
          payload: {},
        },
        shipment: {
          location: {
            code: locationCode,
            zip,
          },
        },
        customer: {
          rightType: getRightType(isLegal),
        },
        payment: {
          paySystemId: isLegal ? legalPaySystemId : paySystemId,
        },
      };

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      // TODO: Удалить когда добавят единый эндпоинт
      const endpoint = isTmpEndpoint ? 'delivery-tariff-by-groups-tmp' : 'checkout/delivery-tariff-by-groups';

      return this.mainHttp.post(`/api/v1/${endpoint}/`, dataRequest)
        .then((res) => {
          const { data } = deserialize(isTmpEndpoint ? res.data : res.data.data);

          // TODO: Удалить когда добавят единый эндпоинт
          const tariffsProp = isTmpEndpoint ? 'potentialDeliveryTariff' : 'checkoutPotentialDeliveryTariff';

          const groups = data.reduce((acc, item) => {
            acc[item.id] = {
              ...item,
              rows: item[tariffsProp].reduce((rowsAcc, row) => {
                const deliveryDate = row?.date;
                const rowData = {
                  id: row.id,
                  name: row.name,
                  code: row.code,
                  codeFormatted: camelCase(row.code),
                  price: Number(row.cost),
                  priceFormatted: Number(row.cost) ? `${priceFormatted(row.cost, { setCurrency: false })} руб.` : 'Бесплатно',
                };
                if (deliveryDate) {
                  Object.assign(rowData, {
                    dateFull: deliveryDate,
                    date: getFormatDateTime(new Date(deliveryDate), 'yyyy-MM-dd'),
                    dateFormatted: formatDate(new Date(deliveryDate), { day: 'numeric', month: 'long' }),
                  });
                }

                // TODO: Удалить когда добавят единый эндпоинт
                if (!isTmpEndpoint) {
                  Object.assign(rowData, {
                    deliveryTimePeriods: row.availableTimeIntervals
                      .reduce((periodsAcc, timeInterval) => {
                        const newInterval = {
                          code: timeInterval.interval,
                          default: timeInterval.isDefault,
                          displayValue: formatTimeInterval(timeInterval.interval),
                        };
                        if (periodsAcc[timeInterval.day]) {
                          periodsAcc[timeInterval.day].push(newInterval);
                        } else {
                          periodsAcc[timeInterval.day] = [newInterval];
                        }
                        return periodsAcc;
                      }, []),
                    availableDates: row.availableDates,
                  });
                }

                rowsAcc[row.code] = rowData;

                return rowsAcc;
              }, {}),
            };

            const cheapestTariff = Object
              .values(acc[item.id].rows)
              .sort((a, b) => a.price - b.price)[0];

            // TODO: Удалить когда добавят единый эндпоинт
            if (!isTmpEndpoint) {
              Object.assign(acc[item.id], {
                price: Number(item.minCost),
                priceFormatted: Number(item.minCost) ? `${priceFormatted(item.minCost, { setCurrency: false })} руб.` : 'Бесплатно',
              });
            } else {
              Object.assign(acc[item.id], {
                price: cheapestTariff.price,
                priceFormatted: cheapestTariff.priceFormatted,
              });
            }

            return acc;
          }, {});

          resolve(groups);
        })
        .catch((error) => {
          if (error.response) {
            const newErrorData = error.response.status === 500 ? { code: error.response.status } : { ...error.response.data };
            if (newErrorData?.code === 0) {
              newErrorData.code = error.response.status;
            }
            const errors = buildErrorCollection(newErrorData, ERRORS_DELIVERY);
            // знаем точно что ожидаем одну ошибку
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(error);
        });
    });
  }

  getFreeDelivery(location) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/sale/order/delivery/free/', {
      LOCATION: location,
    })
      .then(({ data: { data: dataRes } }) => resolve(dataRes))
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 500) {
            return reject(new ServiceError(ERROR_SERVER_ERROR));
          }
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }

  /**
   * Возвращает информацию о прошлом заказе
   * @param {Object} payload
   * @param {string} payload.locationCode
   * @param {('product' | 'basket')} [payload.strategy='basket']
   * @param {number} [payload.productId]
   * @param {number} [payload.quantity=1]
   * @param {string | null} [payload.coupon]
   * @param {string} [payload.basketId]
   * @return {Promise<PreviousOrder>}
   */
  checkPreviousOrder({
    locationCode,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
  }) {
    return new Promise((resolve, reject) => {
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);

      const dataRequest = {
        from: {
          strategy,
        },
        location: {
          code: locationCode,
        },
      };

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      this.mainHttp.post('/api/v1/checkout/by-last-order/', dataRequest,
        { payloadParams: { include: 'order, order.orderShipment, order.orderPayment, order.orderPropertyValue.email, order.orderShipment.deliveryTariff' } })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          if (!data.isPossible) {
            return reject(new ServiceError({ message: 'Предыдущего заказа не найдено', code: '200' }));
          }

          const {
            getContactInfo,
            getRealPayment,
            getPropertyValue,
          } = useOrderPropertyValue();

          const order = data?.order || {};
          const contactInfo = getContactInfo(order.orderPropertyValue);
          const payment = getRealPayment(order);
          const pvzInfo = getPropertyValue(order.orderPropertyValue, 'PVZ_ADDRESS');
          const pvzId = getPropertyValue(order.orderPropertyValue, 'PVZ');

          resolve({
            previousOrder: {
              personType: getRightType(order.isLegalOrder),
              activeDelivery: order.orderShipment.deliveryTariff.deliveryServiceType,
              pvzInfo,
              pvzId,
              deliveryId: order.orderShipment.deliveryId,
              deliveryCode: order.orderShipment.code,
              paySystemId: payment?.paymentSystemId,
              paySystemName: payment?.name,
              lastName: contactInfo.lastName,
              firstName: contactInfo.firstName,
              secondName: contactInfo.secondName,
              email: contactInfo.email,
              phone: contactInfo.phone,
              fullAddress: contactInfo.address,
              index: contactInfo.zip,
              block: contactInfo.block,
              street: contactInfo.street,
              house: contactInfo.house,
              apartment: contactInfo.apartment,
              deliveryDate: null,
              location: contactInfo.location,
              userOrderComment: order.userDescription,
              loyaltyAccount: data.loyaltyAccount,
              loyaltyForCheckout: data.loyaltyForCheckout,
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_SUMMARY);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  saveOrder({
    locationCode,
    locationCoordinates,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
    isLegal = false,
    paySystemId = 31,
    deliveryGroup = null,
    shipmentPayload = null,
    customerAttributes = null,
    analyticsClientId = null,
    useLoyalty = false,
    certificate = null,
    loyaltyAmount = null,
    recommendation,
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const customerType = getRightType(isLegal);

      const legalPaySystemId = getLegalPaySystemId(paySystemId);

      const dataRequest = {
        from: { strategy },
        shipment: {
          deliveryGroup,
          payload: {
            ...shipmentPayload,
            location: { code: locationCode, coordinates: null },
          },
        },
        customer: {
          rightType: customerType,
          attributes: customerAttributes,
        },
        payment: {
          paySystemId: isLegal ? legalPaySystemId : paySystemId,
          loyalty: {
            pay: useLoyalty,
            amount: loyaltyAmount,
          },
          certificate: {
            pay: !!certificate,
            code: certificate,
          },
        },
        analytics: {
          googleAnalytics: {
            clientId: null,
          },
        },
        recommendation: {
          productId: recommendation,
        },
      };

      if (analyticsClientId) {
        dataRequest.analytics.googleAnalytics.clientId = analyticsClientId;
      }

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
        const [latitude, longitude] = locationCoordinates;
        dataRequest.shipment.payload.location.coordinates = { latitude, longitude };
      }

      if (customerAttributes) {
        dataRequest.customer.attributes = customerAttributes;
      }

      return this.mainHttp.post('/api/v1/checkout/order/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve({
            orderId: Number(data.id),
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(error.response.data, ERRORS_ORDER);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  fetchPaySystems({
    locationCode,
    locationCoordinates,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
    rightType = 'individual',
    deliveryGroup = null,
    shipmentPayload,
    certificate,
    customerAttributes = null,
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const dataRequest = {
        from: { strategy },
        location: { code: locationCode },
        shipment: {
          deliveryGroup,
          payload: shipmentPayload,
        },
        customer: {
          rightType,
        },
        payment: {
          certificate: {
            pay: !!certificate,
            code: certificate ?? null,
          },
        },
      };

      if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
        const [latitude, longitude] = locationCoordinates;
        dataRequest.location.coordinates = { latitude, longitude };
      }

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      if (customerAttributes) {
        dataRequest.customer.attributes = customerAttributes;
      }

      return this.mainHttp.post('/api/v1/checkout/payment-methods/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);

          const returnData = {
            paySystems: data.filter(item => item.code !== 'certificates_pay'),
            showCertificate: data.findIndex(item => item.code === 'certificates_pay') !== -1,
          };

          resolve(returnData);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_PAYMENT_SYSTEMS);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  setCertificate(value, maxLengthValue = 12) {
    return new Promise((resolve, reject) => {
      const rowValue = valueReplace(value);
      if (rowValue.length === 0) {
        return reject(new ServiceError(ERROR_CERTIFICATE.emptyNumber.message));
      }
      if (rowValue.length > 0 && rowValue.length < maxLengthValue) {
        return reject(new ServiceError(ERROR_CERTIFICATE.unCorrectNumber.message.replace('{maxLengthValue}', String(maxLengthValue))));
      }

      this.mainHttp.get(`/api/v1/sale/certificate/${value}/`)
        .then(({ data: { data } }) => resolve({
          activeFrom: data.dateActiveFrom || null,
          activeTo: data.dateActiveTo || null,
          type: data.type || null,
          sum: data.value || null,
        }))
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(error.response.data, ERROR_CERTIFICATE);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  /**
   * Возвращает сводную информацию о доставках
   * @param {Object} payload
   * @param {string} payload.locationCode
   * @param {Array<string>} [payload.locationCoordinates]
   * @param {('product' | 'basket')} [payload.strategy='basket']
   * @param {number} [payload.productId]
   * @param {number} [payload.quantity=1]
   * @param {string | null} [payload.coupon]
   * @param {string} [payload.basketId]
   * @return {Promise<unknown>}
   */
  fetchDeliverySummary({
    locationCode,
    locationCoordinates,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const dataRequest = {
        from: { strategy },
        location: { code: locationCode, coordinates: null },
      };

      if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
        const [latitude, longitude] = locationCoordinates;
        dataRequest.location.coordinates = { latitude, longitude };
      }

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      this.mainHttp.post('/api/v1/checkout/delivery-summary/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_DELIVERY_SUMMARY);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Возвращает сводную информацию
   * @param {Object} payload
   * @param {string} payload.locationCode
   * @param {Array<string>} [payload.locationCoordinates]
   * @param {('product' | 'basket')} [payload.strategy='basket']
   * @param {number} [payload.productId]
   * @param {number} [payload.quantity=1]
   * @param {string | null} [payload.coupon]
   * @param {string} [payload.basketId]
   * @param {('shipment' | 'payment' | 'preview')} [payload.stageName='shipment']
   * @param {boolean} [payload.isLegal=false]
   * @param {number} [payload.paySystemId=31]
   * @param {string | null} [payload.deliveryGroup]
   * @param {Object | null} [payload.shipmentPayload]
   * @param {Object | null} [payload.customerAttributes]
   * @param {boolean} [payload.useLoyalty]
   * @param {string | null} [payload.certificate]
   * @param {number | null} [payload.loyaltyAmount]
   * @param {Array<number>} [payload.recommendation]
   * @return {Promise<unknown>}
   */
  fetchSummary({
    locationCode,
    locationCoordinates,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
    stageName = 'shipment',
    isLegal = false,
    paySystemId = 31,
    deliveryGroup = null,
    shipmentPayload = null,
    customerAttributes = null,
    useLoyalty = false,
    certificate = null,
    loyaltyAmount = null,
    recommendation = [],
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const customerType = getRightType(isLegal);
      const legalPaySystemId = getLegalPaySystemId(paySystemId);

      const dataRequest = {
        from: { strategy },
        stage: {
          name: stageName,
          payload: {
            shipment: {},
            customer: {
              rightType: customerType,
              attributes: null,
            },
            payment: {
              paySystemId: isLegal ? legalPaySystemId : paySystemId,
            },
          },
        },
        recommendation: {
          productId: recommendation,
        },
      };

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      if (stageName === 'shipment') {
        dataRequest.stage.payload.shipment.location = { code: locationCode, coordinates: null };
        // наполняем координаты местоположения
        if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
          const [latitude, longitude] = locationCoordinates;
          dataRequest.stage.shipment.location.coordinates = { latitude, longitude };
        }
      }

      if (shipmentPayload) {
        dataRequest.stage.payload.shipment.payload = shipmentPayload;
        dataRequest.stage.payload.shipment.deliveryGroup = deliveryGroup;
        dataRequest.stage.payload.shipment.payload.location = { code: locationCode, coordinates: null };
        // наполняем координаты местоположения
        if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
          const [latitude, longitude] = locationCoordinates;
          dataRequest.stage.shipment.payload.location.coordinates = { latitude, longitude };
        }
      }

      if (customerAttributes) {
        dataRequest.stage.payload.customer.attributes = customerAttributes;
      }

      if (stageName === 'preview') {
        dataRequest.stage.payload.payment.loyalty = { pay: !!useLoyalty, amount: loyaltyAmount };
        dataRequest.stage.payload.payment.certificate = {
          pay: !!certificate,
          code: certificate,
        };
      }

      this.mainHttp.post('/api/v1/checkout/summary/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_SUMMARY);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  fetchPotentialGifts({
    locationCode,
    locationCoordinates,
    strategy = 'basket',
    productId,
    quantity = 1,
    coupon = null,
    basketId,
    isLegal = false,
    paySystemId = 31,
    isGetProduct = true,
  }) {
    return new Promise((resolve, reject) => {
      const { isStrategyBasket, isStrategyProduct } = checkStrategy(strategy);
      checkDefaultSummery({
        strategy,
        productId,
        quantity,
        coupon,
        basketId,
      }, reject);

      const customerType = getRightType(isLegal);
      const legalPaySystemId = getLegalPaySystemId(paySystemId);

      const dataRequest = {
        from: { strategy },
        shipment: {
          location: { code: locationCode, coordinates: null },
        },
        customer: {
          rightType: customerType,
          attributes: null,
        },
        payment: {
          paySystemId: isLegal ? legalPaySystemId : paySystemId,
        },
      };

      let urlRequest = '/api/v1/checkout/potential-gifts/';

      // наполняем координат местоположения
      if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
        const [latitude, longitude] = locationCoordinates;
        dataRequest.shipment.location.coordinates = { latitude, longitude };
      }

      // если стратегия товар, то нужно сразу заполнить обязательные поля
      if (isStrategyProduct) {
        dataRequest.from.payload = { productId, quantity };
        dataRequest.from.payload.coupon = coupon;
      }

      // если стратегия корзина, то нужно сразу заполнить обязательные поля
      if (isStrategyBasket) {
        dataRequest.from.payload = { basketId };
      }

      if (isGetProduct) {
        urlRequest += '?include=product';
      }

      this.mainHttp.post(urlRequest, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_SUMMARY);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Покупка сертификата
   * @param {Object} payload
   * @param {number|string} payload.productId - ID товара
   * @param {number} [payload.quantity=1] - Количество экземпляров товара
   * @param {string} payload.analyticsClientId - Google Analytics Client Id
   * @param {string} payload.locationCode - Код местоположения
   * @param {string[]} payload.locationCoordinates - Координаты
   * @return {Promise<unknown>}
   */
  buyCertificate({ productId, quantity = 1, analyticsClientId, locationCode, locationCoordinates }) {
    return new Promise((resolve, reject) => {
      const dataRequest = {
        productId,
        quantity,
        location: {
          code: locationCode,
          coordinates: null,
        },
        analytics: {
          googleAnalytics: {
            clientId: null,
          },
        },
      };

      if (analyticsClientId) {
        dataRequest.analytics.googleAnalytics.clientId = analyticsClientId;
      }

      if (locationCoordinates && Array.isArray(locationCoordinates) && locationCoordinates.length) {
        const [latitude, longitude] = locationCoordinates;
        dataRequest.location.coordinates = { latitude, longitude };
      }

      this.mainHttp.post('/api/v1/checkout/certificate/', dataRequest)
        .then(({ data: { data: dataRes } }) => resolve(dataRes.data))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_BASKET);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
