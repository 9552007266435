import { computed, nextTick, ref } from '@nuxtjs/composition-api';
import { useVuelidate } from '@vuelidate/core';
import {
  email as emailValidator,
  helpers,
  required,
  numeric,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useThrottleFn } from '@vueuse/core';
import {
  checkCommentCourier,
  checkApartment,
  checkHouse,
  checkIndex,
  checkStreet,
  checkBlock,
} from '@/modules/checkout/utils/validationsCourier';
import {
  account,
  checkLength,
  checkName,
  checkPhone,
  checkPhoneWithCode,
  firstEmailSymbols,
  maxLengthName,
  minLengthReview,
  maxLengthReview,
  notCyrillic,
  organizationNameLength,
  isAllowedSymbolsFullName,
  isMinLengthSymbolsFullName,
  checkFullName,
  checkSecondName,
  checkMinRegistrationName,
  checkMaxRegistrationName,
  checkRegistrationName,
  bik as bikValidator,
  inn as innValidator,
  kpp as kppValidator,
  minAge,
  correctAgeRange,
  minAgeChild,
  maxAge,
  nameIsNotReader,
} from '@/utils/formsVue/vueValidators';
import { checkMobile } from '@/utils/deviceCheck';
import {
  B2B_FORM_CALLBACK,
  B2B_FORM_QUESTIONS,
  B2B_FORM_REQUEST,
  B2B_PRICE_LIST_FORM,
} from '@/utils/constants/b2bConstants';
import { CHECKOUT, REGISTRATION } from '@/utils/constants/authorizationConstants';
import { PRODUCT_DETAIL_FORM_SUBSCRIBE, PRODUCT_DETAIL_FORM_REVIEW } from '@/utils/constants/productDetailConstants';
import { SALE_FORM_SUBSCRIBE } from '@/utils/constants/salesConstants';
import { MNOGORU_COUPON_LENGTH } from '@/utils/constants/globalConst';
import {
  SUBSCRIPTION_FORM,
  FEEDBACK_FORM,
  CALLBACK_FORM,
  PERSONAL_PROFILE_FORM,
  COURIER_FORM,
  ADDRESS_FORM,
  REVIEWS_FORM,
  CANCEL_ORDER_FORM,
} from '@/utils/constants/formConstants';
import fieldValidation from '@/utils/formsVue/fieldValidation';

import useHttpClient from '@/compositions/useHttpClient';

export const useVuelidateForm = (formData, formType) => {
  const { user } = useHttpClient();
  const { withAsync } = helpers;

  const isMobile = checkMobile();

  const secondNameRequired = ref(false);

  const addressRequired = ref(false);

  const indexRequired = ref(false);

  const isReasonOther = ref(false);

  const displayHouseStreetRequired = ref(false);

  const displayStreetRequired = ref(false);

  const domainChecking = ref(false);

  const checkOldNames = () => {
    const splitFirstName = formData.name.value?.split(' ')?.filter(Boolean) || [];
    return !(splitFirstName.length > 1 && !formData.lastName.value);
  };

  const checkMnogoru = value => (value.length === MNOGORU_COUPON_LENGTH || !value) && formData.customMnogoRuError === '';

  const checkDomain = useThrottleFn(async (email, resolve) => {
    if (!email) return;
    // eslint-disable-next-line no-use-before-define
    const invalid = !!v$?.value.email.emailValidator.$invalid;
    if (invalid) return false;
    domainChecking.value = true;
    const res = await user.checkEmailDomain(email);
    domainChecking.value = false;
    resolve(res);
  }, 500);

  const companyRules = ref({});

  const phoneRules = ref({});

  const emailRules = ref({
    required,
    emailValidator,
    notCyrillic,
    firstEmailSymbols,
    checkDomain: withAsync(email => new Promise(resolve => checkDomain(email, resolve))),
  });

  const namesRules = computed(() => {
    if (isMobile) {
      return secondNameRequired.value ? {
        name: { nameIsNotReader, checkName, checkLength, required },
        lastName: { nameIsNotReader, checkName, checkLength, required },
        secondName: { nameIsNotReader, checkName, checkLength, required },
      } : {
        name: { nameIsNotReader, checkName, checkLength, required },
        lastName: { nameIsNotReader, checkName, checkLength, required },
        secondName: { nameIsNotReader, checkName, checkLength },
      };
    }

    return secondNameRequired.value ? {
      fullName: {
        isAllowedSymbolsFullName,
        checkFullName,
        isMinLengthSymbolsFullName,
        checkOldNames,
        checkSecondName,
        nameIsNotReader,
      },
    } : {
      fullName: {
        isAllowedSymbolsFullName,
        checkFullName,
        isMinLengthSymbolsFullName,
        checkOldNames,
        nameIsNotReader,
      },
    };
  });

  const addressRules = computed(() => {
    const schema = {};

    schema.index = indexRequired.value ? {
      numeric,
      checkIndex,
      minLength: minLength(2),
    } : {};

    if (displayHouseStreetRequired.value) {
      schema.displayHouse = {
        required,
        checkHouse,
      };
      schema.displayStreet = {
        required,
        checkStreet,
        minLength: minLength(2),
        maxLength: maxLength(35),
      };
      schema.block = { checkBlock };
    }

    if (isMobile) {
      schema.displayHouse = { checkHouse };
      schema.displayStreet = displayStreetRequired.value ? { checkStreet } : {};
    } else {
      schema.address = addressRequired.value ? {
        required,
        checkHouse,
      } : {};
    }

    return schema;
  });

  const cancelOrderRules = computed(() => {
    const schema = {};

    if (isReasonOther.value) {
      Object.assign(schema, {
        title: { required, maxLength: maxLength(280) },
      });
    }

    return { reason: schema };
  });

  const rules = computed(() => {
    switch (formType) {
      case REGISTRATION:
        return {
          email: emailRules.value,
          firstName: {
            checkRegistrationName,
            checkMinRegistrationName,
            checkMaxRegistrationName,
            required,
          },
          lastName: {
            checkRegistrationName,
            checkMinRegistrationName,
            checkMaxRegistrationName,
            required,
          },
        };

      case B2B_FORM_REQUEST.type:
        return {
          email: emailRules.value,
          phone: phoneRules.value,
          name: { checkName, maxLengthName, required },
          company: { organizationNameLength, required },
          question: { required },
        };

      case B2B_FORM_CALLBACK.type:
        return {
          phone: phoneRules.value,
          name: { checkName, maxLengthName, required },
        };

      case B2B_PRICE_LIST_FORM.type:
      case PRODUCT_DETAIL_FORM_SUBSCRIBE.type:
      case SALE_FORM_SUBSCRIBE.type:
      case SUBSCRIPTION_FORM.type:
        return {
          email: emailRules.value,
        };

      case B2B_FORM_QUESTIONS.type:
        return {
          name: { checkName, required, maxLengthName },
          theme: { required },
          email: emailRules.value,
          question: { required },
        };

      case CHECKOUT:
        return {
          email: emailRules.value,
          phone: phoneRules.value,
          ...namesRules.value,
          ...companyRules.value,
        };

      case COURIER_FORM.type:
        return {
          comment: { checkCommentCourier },
        };

      case ADDRESS_FORM.type: {
        return {
          apartment: { checkApartment },
          ...addressRules.value,
        };
      }

      case PRODUCT_DETAIL_FORM_REVIEW.type:
      case REVIEWS_FORM.type:
        return {
          comment: { minLengthReview, maxLengthReview, required },
        };

      case FEEDBACK_FORM.type:
        return {
          name: { nameIsNotReader, checkName, required },
          email: emailRules.value,
          question: { required },
        };

      case CALLBACK_FORM.type:
        return {
          phone: phoneRules.value,
        };

      case PERSONAL_PROFILE_FORM.main:
        return {
          email: emailRules.value,
          firstName: { nameIsNotReader, checkName, checkLength, required },
          lastName: { nameIsNotReader, checkName, checkLength, required },
          secondName: { nameIsNotReader, checkName, checkLength },
          cards: {
            mnogoru: {
              numeric,
              mnogoru: withAsync(value => checkMnogoru(value)),
            },
          },
          birthday: { minAge, correctAgeRange },
        };

      case PERSONAL_PROFILE_FORM.children:
        return {
          name: { checkName, checkLength },
          gender: { required },
          birthday: { required, minAgeChild, maxAge },
        };

      case CANCEL_ORDER_FORM.type:
        return { ...cancelOrderRules.value };

      default:
        return {};
    }
  });

  const v$ = useVuelidate(rules, formData);

  const invalid = computed(() => (!!v$.value.$invalid));

  const setPhoneRules = (enabled = true, validators = { checkPhoneWithCode, checkPhone }) => {
    phoneRules.value = enabled ? validators : {};
  };

  const setCompanyRules = (enabled = false) => {
    companyRules.value = enabled ? {
      inn: { innValidator, numeric, required },
      organizationName: { organizationNameLength, required },
      legalAddress: { required },
      kpp: { kppValidator, numeric },
      bik: { bikValidator, numeric, required },
      correspondentAccount: { account, numeric, required },
      checkingAccount: { account, numeric, required },
      bankName: { required },
    } : {};
  };

  const setSecondNameRules = (enabled = false) => {
    secondNameRequired.value = enabled;
  };

  const setAddressRules = (enabled = false) => {
    addressRequired.value = enabled;
  };

  const setIndexRules = (enabled = false) => {
    indexRequired.value = enabled;
  };

  const setReasonOtherRules = (enabled = false) => {
    isReasonOther.value = enabled;
  };

  const setDisplayHouseStreetRules = (enabled = false) => {
    displayHouseStreetRequired.value = enabled;
  };

  const setDisplayStreetRules = (enabled = false) => {
    displayStreetRequired.value = enabled;
  };

  const onValidate = async () => {
    setPhoneRules();
    await nextTick();
    v$.value.$touch();
  };

  const onReset = () => {
    setPhoneRules(false);
    v$.value.$reset();
  };

  return {
    v$,
    invalid,
    domainChecking,
    fieldValidation,
    onValidate,
    onReset,
    setPhoneRules,
    setCompanyRules,
    setSecondNameRules,
    setAddressRules,
    setIndexRules,
    setReasonOtherRules,
    setDisplayHouseStreetRules,
    setDisplayStreetRules,
  };
};
