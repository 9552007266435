import { priceFormatted } from '@/utils/formatters/formattedPrice';
import { cdnType, productImage } from '@/utils/constants/catalogConstants';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';

/**
 * * Возвращает список картинок
 * @param {Object} [data={}]
 * @param {string} data.cdnType - Тип картинки (eksmo, book24)
 * @param {string} data.url - Урл до основной обложки
 * @param {Object} [options={}]
 * @param {number} [options.sizePicWidth=220] Ширина картинки
 * @param {number} [options.sizePicHeight=284] Высота картинки
 * @return {Readonly<{webpX2: (null|string), x1: (null|string), x2: (null|string), webpX1: (null|string), type: (null|string)}>}
 */
export const pictures = (data = {}, options = {}) => {
  const {
    // eslint-disable-next-line no-unused-vars
    sizePicHeight = 284,
    sizePicWidth = 220,
  } = options;
  if (data) {
    let result = {
      type: data?.cdnType || null,
      x1: null,
      x2: null,
      webpX1: null,
      webpX2: null,
    };
    const type = cdnType[data.cdnType];
    switch (data.cdnType) {
      case cdnType.eksmo.value: {
        const [url] = data.url.split(type.baseUrl).filter(item => item);
        const [basePath, format] = url.split('.').filter(item => item);
        result.x1 = `${type.baseUrl}${basePath}__w${sizePicWidth}.${format}`;
        result.x2 = `${type.baseUrl}${basePath}__w${sizePicWidth * 2}.${format}`;
        result.webpX1 = `${type.baseUrl}${basePath}__w${sizePicWidth}.webp`;
        result.webpX2 = `${type.baseUrl}${basePath}__w${sizePicWidth * 2}.webp`;
        break;
      }
      case cdnType.book24.value: {
        result = formattedPicturesCdnBook24(data.url, options);
        break;
      }
    }
    return Object.freeze(result);
  }
  return productImage;
};

/**
 * Нормализует список авторов товара
 * @param {Array} [list=[]] - Список авторов
 * @return {SeriesItemPreview[]}
 */
export const authorList = (list = []) => {
  if (!list || !list.length) return [];
  const result = [];
  list.forEach((item) => {
    if (item.type === 'internalAuthor') {
      result.push({
        name: item.name,
        id: Number(item.id),
        link: item?.url || item.code ? `/author/${item.code}-${item.id}/` : null,
        code: item?.code,
      });
    }
  });
  return result;
};

/**
 * @typedef {Object} SeriesItemPreview
 * @property {string|number} id
 * @property {string} name
 * @property {string} code
 * @property {string|null} link
 * @property {string} xmIId
 * @property {number} numBooks
 * @property {string[]} seriesBookImage
 */

/**
 *
 * @param {Object.<string, any>} item
 * @return {SeriesItemPreview}
 */
export const formattedSeriesItem = item => ({
  id: item.id,
  name: item.name,
  code: item.code,
  link: item?.detailUrl || item.code ? `/serie/${item.code}-${item.id}/` : null,
  xmIId: item.xmIId,
  numBooks: item.numBooks,
  seriesBookImage: item.relationships?.seriesBookImage?.src || [],
});

/**
 * Нормализует список серий
 * @param {Array<Object.<string, any>>} [list=[]] - Список серий
 * @return {SeriesItemPreview[]}
 */
const seriesList = (list = []) => {
  if (!Array.isArray(list) || !list.length) return [];
  const result = [];
  list.forEach((item) => {
    if (item.type === 'series') {
      result.push(formattedSeriesItem(item));
    }
  });
  return result;
};

/**
 * Нормализует категорию
 * @param {object} data - категория
 * @returns {}
 */
const categoryFormatter = (data = {}) => {
  let result = null;

  if (Object.keys(data).length) {
    result = {
      id: data.id,
      code: data.code,
      name: data.name,
      link: `/category/${data.code}-${data.id}/`,
      parentId: data.parentId,
      depthLevel: data.depthLevel,
      countProducts: data.countProducts,
    };
  }
  return result;
};

/**
 * Нормализует издательство
 * @param {object} data - издательство
 * @returns {}
 */
const publishingHouseFormatter = (data = {}) => {
  let result = null;

  if (Object.keys(data).length) {
    result = {
      id: data.id,
      code: data.code,
      name: data.name,
      link: `/brand/${data.code}-${data.id}/`,
    };
  }
  return result;
};

/**
 * Возвращает отформатированные данные по бейджу
 * @param {Object} badgeInfo
 * @return {{mods: string, icon: string, text: string, percent: string|number}|null}
 */
const getBadgeInfo = (badgeInfo) => {
  if (!badgeInfo || !badgeInfo.showInList) return null;
  return {
    text: badgeInfo.discountText,
    percent: badgeInfo.discountPercent,
    mods: badgeInfo.schema ? badgeInfo.schema : badgeInfo.mode,
    mode: badgeInfo.mode || badgeInfo.schema,
    icon: badgeInfo.iconId,
  };
};

/**
 * Возвращает отформатированные данные по бейджу в детальной товара
 * @param {Object} badgeInfo
 * @return {{mods: string, icon: string, text: string, percent: string|number}|null}
 */
const getBadgeInfoProductDetail = (badgeInfo) => {
  if (!badgeInfo || !badgeInfo.showInCard) return null;
  return {
    text: badgeInfo.discountText,
    percent: badgeInfo.discountPercent,
    mods: badgeInfo.schema ? badgeInfo.schema : badgeInfo.mode,
    mode: badgeInfo.mode || badgeInfo.schema,
    icon: badgeInfo.iconId,
  };
};

const getCategoryListName = (data) => {
  if (!data) return [];
  const list = [];
  const category = { ...data };

  if (category.relationships?.parentCategory) {
    category.relationships.parentCategory
      .sort((a, b) => {
        if (a.depthLevel > b.depthLevel) {
          return 1;
        }
        if (a.depthLevel < b.depthLevel) {
          return -1;
        }
        return 0;
      })
      .forEach((item) => {
        list.push(item.name);
      });
  }
  list.push(category.name);

  return list;
};

export const getPrices = (amount) => {
  if (!amount) throw new Error('Не указан объект цены');
  return {
    price: Number(amount?.discounted),
    priceFormatted: priceFormatted(amount?.discounted),
    base: Number(amount?.total),
    baseFormatted: priceFormatted(amount?.total),
  };
};

/**
 * @typedef {Object} ProductInfo
 * @property {number} id
 * @property {string} code
 * @property {string} name
 * @property {string} link
 * @property {boolean} isRrc
 * @property {boolean} isAdult
 * @property {boolean} isAvailable
 * @property {boolean} isBestseller
 * @property {boolean} isBestPrice
 * @property {boolean} isBuyOnlyPrePaid
 * @property {boolean} isCertificate
 * @property {boolean} isDemandPrint
 * @property {boolean} isNovelty
 * @property {boolean} isPreOrder
 * @property {boolean} isPromoDisabled
 * @property {number} discountPercent
 * @property {number} weight
 * @property {number} quantity
 * @property {Array} authorList
 * @property {string|null} description
 * @property {Object|null} publishingHouse
 * @property {Object|null} category
 * @property {Array} series
 * @property {Object} prices
 * @property {number} prices.base
 * @property {string} prices.baseFormatted
 * @property {number} prices.price
 * @property {string} prices.priceFormatted
 * @property {Object|null} badge
 * @property {string} badge.mods?
 * @property {string} badge.icon?
 * @property {string} badge.text?
 * @property {string|number} badge.percent?
 * @property {number|null} rating
 * @property {Object} images
 * @property {string} lastReleaseDate
 * @property {Array} quotes
 * @property {string} label
 * @property {string} nomcode
 * @property {Array} categoryListName
 * @property {number} age
 */

/**
 * Нормализует данные товара
 * @param {Object} data Объект товара из API
 * @param {Object} [options={}]
 * @param {number} [options.sizePicWidth=220] Ширина картинки
 * @param {number} [options.sizePicHeight=284] Высота картинки
 * @returns {ProductInfo}
 */
export default function formattedProduct(data = {}, options = {}) {
  const { sizePicWidth = 220, sizePicHeight = 284 } = options;

  let badgeInfo = {};
  if (data.relationships?.badge && data.relationships.badge.length) {
    badgeInfo = { ...data.relationships.badge[0] };
  }

  const labels = [];
  if (data.isBestseller) {
    labels.push({
      title: 'Бестселлер',
      isBestseller: true,
    });
  }
  if (data.isNovelty) {
    labels.push({
      title: 'Новинка',
      isNovelty: true,
    });
  }

  return {
    id: Number(data.id),
    code: data.code,
    name: data.name,
    link: `/product/${data.code}-${data.id}/`,
    isRrc: data.isRrc,
    isAdult: data.isAdult,
    isAvailable: data.isAvailable && (Number(data.amount?.discounted) > 0), // !isNotAvailable
    isBestseller: data.isBestseller,
    isBestPrice: data.isBestPrice,
    isBuyOnlyPrePaid: data.isBuyOnlyPrePaid, // isPrePay
    isCertificate: data.isCertificate,
    isDemandPrint: data.isDemandPrint,
    isNovelty: data.isNovelty, // isNew
    isPreOrder: data.isPreOrder,
    isPromoDisabled: data.isPromoDisabled, // promoDisabled
    discountPercent: Number(data.discountPercent),
    weight: data.weight,
    quantity: data.quantity,
    authorList: authorList(data.relationships?.author || []),
    author: data.relationships?.author ?? [],
    prices: getPrices(data.amount),
    amount: data.amount,
    images: pictures(data.picture, { sizePicWidth, sizePicHeight }),
    picture: data?.picture ?? null,
    lastReleaseDate: data.lastReleaseDate,
    series: seriesList(data.relationships?.series || []),
    description: data.relationships?.productText?.detailDescription || null,
    quotes: data.relationships?.quote || [],
    labels,
    category: categoryFormatter(data.relationships?.category),
    badge: getBadgeInfo(badgeInfo),
    badgeProductDetail: getBadgeInfoProductDetail(badgeInfo),
    nomcode: data.nomcode,
    publishingHouse: publishingHouseFormatter(data.relationships?.publishingHouse),
    categoryListName: getCategoryListName(data.relationships?.category),
    age: data.ageLimit,
    bookId: data.relationships?.orsProduct?.bookId,
    tbkCode: data.relationships?.orsProduct?.tbkCode,
  };
}
