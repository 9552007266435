/**
 * @typedef {Object} StepItem
 * @property {string} label - Название шага
 * @property {string} icon - Иконка
 * @property {Array} thresholdValues - Значения при которых шаг отмечается пройденным
 */

/**
 * @typedef {Object} AvailableStatus
 * @property {string} awaitingPayment - Ожидает оплаты
 * @property {string} preOrder - Предзаказ
 * @property {string} newOne - Новый
 * @property {string} assembled - Скомплектован
 * @property {string} assembleInProgress - Комплектуется
 * @property {string} onTheWay - В пути
 * @property {string} receivedAtPoint - Поступил в пункт выдачи
 * @property {string} canceled - Отменен
 * @property {string} completed - Выполнен
 */

/**
 * Возможные текстовые описание статусов заказа
 * @readonly
 * @type {AvailableStatus}
 */
const AVAILABLE_STATUSES = Object.freeze({
  awaitingPayment: 'Ожидает оплаты',
  preOrder: 'Предзаказ',
  newOne: 'Новый',
  assembled: 'Скомплектован',
  assembleInProgress: 'Комплектуется',
  onTheWay: 'В пути',
  receivedAtPoint: 'В пункте',
  canceled: 'Отменен',
  completed: 'Выполнен',
});

/**
 * Статусы текущего состояния заказа
 * @type {Readonly<{P: string, TU: string, OC: string, T: string, E: string, CL: string, K: string, L: string, N: string, PO: string, O: string}>}
 */

export const ORDER_STATUSES = Object.freeze({
  PO: AVAILABLE_STATUSES.preOrder,
  N: AVAILABLE_STATUSES.newOne,
  OC: AVAILABLE_STATUSES.newOne,
  E: AVAILABLE_STATUSES.newOne,
  O: AVAILABLE_STATUSES.awaitingPayment,
  K: AVAILABLE_STATUSES.awaitingPayment,
  P: AVAILABLE_STATUSES.assembleInProgress,
  T: AVAILABLE_STATUSES.assembleInProgress,
  L: AVAILABLE_STATUSES.assembleInProgress,
  CL: AVAILABLE_STATUSES.assembleInProgress,
  TU: AVAILABLE_STATUSES.assembleInProgress,
  PK: AVAILABLE_STATUSES.assembled,
  SP: AVAILABLE_STATUSES.assembled,
  RS: AVAILABLE_STATUSES.assembled,
  RE: AVAILABLE_STATUSES.assembled,
  DS: AVAILABLE_STATUSES.onTheWay,
  DA: AVAILABLE_STATUSES.onTheWay,
  R: AVAILABLE_STATUSES.onTheWay,
  DP: AVAILABLE_STATUSES.onTheWay,
  DC: AVAILABLE_STATUSES.onTheWay,
  PF: AVAILABLE_STATUSES.receivedAtPoint,
  F: AVAILABLE_STATUSES.completed,
  PZ: AVAILABLE_STATUSES.canceled,
  LW: AVAILABLE_STATUSES.canceled,
  C: AVAILABLE_STATUSES.canceled,
  BS: AVAILABLE_STATUSES.canceled,
  BW: AVAILABLE_STATUSES.canceled,
  BC: AVAILABLE_STATUSES.canceled,
  B: AVAILABLE_STATUSES.canceled,
  CA: AVAILABLE_STATUSES.canceled,
  CS: AVAILABLE_STATUSES.canceled,
});

/**
 * Возможные текстовые описание статусов заказа
 * @readonly
 */
export const STATUSES_BY_TYPE = Object.freeze({
  newOne: ['N', 'OC', 'E'],
  awaitingPayment: ['O', 'K'],
  assembleInProgress: ['P', 'T', 'L', 'CL', 'TU'],
  assembled: ['PK', 'SP', 'RS', 'RE'],
  onTheWay: ['DS', 'DA', 'R', 'DP', 'DC'],
  receivedAtPoint: ['PF'],
  canceled: ['PZ', 'LW', 'C', 'BS', 'BW', 'BC', 'B', 'CA', 'CS'],
  completed: ['F'],
});

/**
 * Шаг: Предзаказ
 * @type {Readonly<StepItem>}
 */
export const STEP_PREORDER = Object.freeze({
  label: AVAILABLE_STATUSES.preOrder,
  icon: 'NewIcon',
  thresholdValues: ['PO'],
});

/**
 * Шаг: Ожидает оплаты
 * @type {StepItem}
 */
export const STEP_AWAITING_PAYMENT = Object.freeze({
  label: AVAILABLE_STATUSES.awaitingPayment,
  icon: 'NewIcon',
  thresholdValues: STATUSES_BY_TYPE.awaitingPayment,
});

/**
 * Шаг: Новый
 * @type {StepItem}
 */
export const STEP_NEW = Object.freeze({
  label: AVAILABLE_STATUSES.newOne,
  icon: 'NewIcon',
  thresholdValues: STATUSES_BY_TYPE.newOne,
});

/**
 * Шаг: Комплектуется
 * @type {StepItem}
 */
export const STEP_ASSEMBLE_IN_PROGRESS = Object.freeze({
  label: AVAILABLE_STATUSES.assembleInProgress,
  icon: 'PaidIcon',
  thresholdValues: STATUSES_BY_TYPE.assembleInProgress,
});

/**
 * Шаг: Скомплектован
 * @type {StepItem}
 */
export const STEP_ASSEMBLED = Object.freeze({
  label: AVAILABLE_STATUSES.assembled,
  icon: 'PackedIcon',
  thresholdValues: STATUSES_BY_TYPE.assembled,
});

/**
 * Шаг: В пункте
 * @type {StepItem}
 */
export const STEP_RECEIVED_AT_POINT = Object.freeze({
  label: AVAILABLE_STATUSES.receivedAtPoint,
  icon: 'CourierIcon',
  thresholdValues: STATUSES_BY_TYPE.receivedAtPoint,
});

/**
 * Шаг: В пути
 * @type {StepItem}
 */
export const STEP_ON_THE_WAY = Object.freeze({
  label: AVAILABLE_STATUSES.onTheWay,
  icon: 'CourierIcon',
  thresholdValues: STATUSES_BY_TYPE.onTheWay,
});

/**
 * Шаг: Выполнен
 * @type {StepItem}
 */
export const STEP_COMPLETED = Object.freeze({
  label: AVAILABLE_STATUSES.completed,
  icon: 'ReceivedIcon',
  thresholdValues: STATUSES_BY_TYPE.completed,
});
