import { nanoid } from 'nanoid';

import { typeChars, charUnitType } from '@/utils/constants/productDetailConstants';
import { pictures } from '@/utils/formatters/formattedProduct';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';
import { checkMobile } from '@/utils/deviceCheck';

/**
 * @typedef {Object} ProductCharacteristic - характеристика товара
 * @property {string} id - идентификатор характеристики
 * @property {string} name - название характеристики
 * @property {string|null} targetUrl - ссылка на детальную страницу
 * @property {string} type - тип характеристики
 * @property {string|null} unitType - единица изменения значения
 * @property {string} value - значение характеристики
 */

/**
 * @typedef {Object} FormattedProductCharacteristic - характеристика товара
 * @property {string} id - идентификатор характеристики
 * @property {string} name - название характеристики
 * @property {string|null} targetUrl - ссылка на детальную страницу
 * @property {string} type - тип характеристики
 * @property {string|null} unitType - единица изменения значения
 * @property {string|ProductCharacteristic[]} value - значение характеристики
 * @property {number} position - номер позиции характеристики
 */

/**
 * @param {ProductCharacteristic[]} list
 * @param list
 * @return {FormattedProductCharacteristic[]}
 */
export const formattedProductCharacteristics = (list = []) => {
  let newList = [];
  list.forEach((item) => {
    const filteredList = list.filter(itemFilter => item.type === itemFilter.type);
    const tt = newList.filter(i => i.type === item.type);
    if (!tt.length) {
      newList.push(
        filteredList.length > 1
          ? {
            id: nanoid(),
            name: filteredList[0].name,
            type: filteredList[0].type,
            targetUrl: null,
            unitType: charUnitType[filteredList[0].unitType]
              ? charUnitType[filteredList[0].unitType].label
              : item.unitType,
            value: filteredList.map(itemMap => itemMap),
          }
          : {
            ...item,
            value: item.type === charUnitType.weight.type
              ? (item.value / 1000).toFixed(2)
              : item.value,
            unitType: charUnitType[filteredList[0].unitType]
              ? charUnitType[item.unitType].label
              : item.unitType,
          },
      );
    }
  });

  newList = newList
    .map(item => ({
      ...item,
      position: typeChars[item.type] ? typeChars[item.type].position : 99,
    }))
    .sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      return 0;
    });

  return newList;
};

export const formattedAverage = (average) => {
  if (!average) {
    return '0,0';
  }
  return Intl.NumberFormat('ru-RU').format(average.toFixed(1));
};

export const formattedMarksRating = (marks = []) => {
  if (!Array.isArray(marks)) return [];

  return marks.sort((a, b) => {
    if (a.rating > b.rating) {
      return 1;
    }
    if (a.rating < b.rating) {
      return -1;
    }
    return 0;
  }).map(item => (Number(item.average)));
};

export const formattedMorePicturesList = (picturesList = []) => {
  if (!Array.isArray(picturesList)) throw new Error('[formattedProductDetail:formattedMotePictures] picturesList должен быть массивом');

  const newList = [];
  picturesList.forEach((item) => {
    const main = pictures(item, {
      sizePicHeight: 590,
      sizePicWidth: 410,
    });
    const thumb = pictures(item, {
      sizePicHeight: 80,
      sizePicWidth: 80,
    });
    const big = pictures(item, {
      sizePicHeight: 596,
      sizePicWidth: 410,
    });
    const bigForGallery = pictures(item, {
      sizePicHeight: 490,
      sizePicWidth: 865,
    });
    const bigThumb = pictures(item, {
      sizePicHeight: 90,
      sizePicWidth: 90,
    });

    const imgData = {
      main,
      thumb,
      big,
      bigThumb,
      bigForGallery,
    };

    if (item.description) imgData.description = item.description;
    newList.push(imgData);
  });

  return newList;
};

export const formattedMainPicture = (picture) => {
  const isMobileLocal = checkMobile();

  const main = pictures(picture, {
    sizePicHeight: isMobileLocal ? 540 : 590,
    sizePicWidth: isMobileLocal ? 337 : 410,
  });
  const thumb = pictures(picture, {
    sizePicHeight: 80,
    sizePicWidth: 80,
  });
  const big = pictures(picture, {
    sizePicHeight: 596,
    sizePicWidth: 410,
  });
  const bigThumb = pictures(picture, {
    sizePicHeight: 90,
    sizePicWidth: 90,
  });

  return {
    main,
    thumb,
    big,
    bigThumb,
  };
};

/**
 * @typedef {Object} ProductAvailability
 * @property {string} id - идентификатор
 * @property {string} type - Тип товар
 * @property {Object} amount - Стоимость товара
 * @property {string} amount.total - Стоимость товара без скидок
 * @property {string} amount.discounted - Стоимость товара с учётом скидок
 * @property {string} availability - Доступность товар
 * @property {number} discountPercent - Процент скидки
 */

/**
 * @param {ProductAvailability} data
 * @return {{isAvailable: boolean, prices: {baseFormatted: string, price: number, priceFormatted: string, base: number}, productType}}
 */
export const formattedAvailableProduct = (data) => {
  const priceFormattedFunc = (prices) => {
    if (!prices) return null;
    return {
      price: Number(prices?.discounted || 0),
      base: Number(prices?.total || 0),
    };
  };

  return {
    isAvailable: data.availability === 'available',
    productType: data.type,
    prices: priceFormattedFunc(data.amount),
    discountPercent: Number(data.amount.discountPercent),
    priceForAuthorized: Number(data.amount.discountedForAuthorized),
  };
};

export const formattedReviewPicture = (list) => {
  if (!Array.isArray(list)) return [];
  const newList = [];

  list.forEach((item) => {
    const big = formattedPicturesCdnBook24(item.src, {
      sizePicHeight: 900,
      sizePicWidth: 600,
    });
    const bigThumb = formattedPicturesCdnBook24(item.src, {
      sizePicHeight: 90,
      sizePicWidth: 90,
    });

    newList.push({
      id: item.id,
      big,
      bigThumb,
    });
  });

  return newList;
};

export const formattedShield = ({ badge, shield }) => {
  let shieldProduct = null;
  if (shield) {
    shieldProduct = {
      ...shield,
      type: 'shield',
    };
  } else if (badge && badge.showInCard) {
    shieldProduct = {
      type: 'badge',
      title: badge.discountText,
    };
  }

  return shieldProduct;
};

export const formattedReviewsPhoto = (list = []) => {
  if (!Array.isArray(list)) return [];

  const urlReplace = (url) => {
    if (typeof url !== 'string') return url;
    return url.replace('http:', '');
  };

  const newList = [];

  list.forEach((item) => {
    const big = formattedPicturesCdnBook24(urlReplace(item.url), {
      sizePicHeight: 900,
      sizePicWidth: 600,
    });
    const bigThumb = formattedPicturesCdnBook24(urlReplace(item.url), {
      sizePicHeight: 90,
      sizePicWidth: 90,
    });

    newList.push({
      id: item.id,
      big,
      bigThumb,
    });
  });

  return newList;
};
