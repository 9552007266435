<template>
  <article
    class="product-card"
    :class="{'_without-button': !showButtons}"
    data-b24-product-card
    :data-b24-id="getProductProperty(product, productProperty.id)"
    :data-b24-base-id="getProductProperty(product, productProperty.baseId)"
    :data-b24-price="getProductProperty(product, productProperty.price)"
    :data-b24-name="getProductProperty(product, productProperty.name)"
    :data-b24-brand="getProductProperty(product, productProperty.brand)"
    :data-b24-discount="getProductProperty(product, productProperty.discount)"
    :data-b24-category="getProductProperty(product, productProperty.category)"
    :data-b24-available="getProductProperty(product, productProperty.available)"
    :data-b24-preorder="getProductProperty(product, productProperty.preorder)"
    :data-b24-index="productIndex"
    :data-b24-item-list-name="$route.name"
  >
    <div class="product-card__image-holder">
      <product-card-link
        :link="link"
        :title="product.name"
        :handler="handlerClickProduct"
        class="product-card__image-link"
      >
        <app-picture
          class-picture="product-card__picture"
          :alt="`Купить ${product.name} — Фото №1`"
          class-img="product-card__image"
          :fallback-image="product.fallbackImage"
          :item="product.images"
          :is-adult="checkIsAdult && product.isAdult"
          :show-adult-badge="checkIsAdult && product.isAdult"
        />
      </product-card-link>
      <badge-best-price
        v-if="isShowBestPriceBadge"
        class="product-card__best-price"
      />
      <card-labels
        v-if="isShowLabels"
        :labels="labels"
      />
    </div>
    <div class="product-card__content">
      <div v-if="showBadge" class="product-card__badges">
        <card-badge
          :icon="product.badge.icon"
          :class="product.badge.mods"
          :percent="product.badge.percent"
          class="product-card__badge"
        />
      </div>
      <client-only>
        <card-price
          v-if="product.isAvailable"
          class="product-card__price"
          :price="product.prices.price"
          :old-price="product.prices.base"
          :discount="product.discountPercent"
          :show-discount="showDiscountNearPrice"
        />
        <template #placeholder>
          <content-placeholders rounded style="height: 24px;">
            <content-placeholders-img style="height: 18px; width: 150px; border-radius: 9px;" />
          </content-placeholders>
        </template>
      </client-only>
      <product-card-link
        :link="link"
        :title="product.name"
        :handler="handlerClickProduct"
        class="product-card__name"
      >
        {{ product.name }}
      </product-card-link>
      <author-list
        v-if="product.authorList.length"
        :author-list="product.authorList"
        class="product-card__authors-holder"
      />
      <div v-if="showButtons" class="product-card__buttons-holder">
        <card-main-button
          class="product-card__add-to-cart"
          :product-id="product.id"
          :quantity="1"
          :link="link"
          :is-added-to-cart="isAddedToCart"
          :is-pre-order="product.isPreOrder"
          :is-not-available="!product.isAvailable"
          :is-available-button-short="isAvailableButtonShort"
          :is-certificate="product.isCertificate"
          :is-fetch-basket-after-add-to-basket="isFetchBasketAfterAddToBasket"
          :is-loading-fetch-basket="isLoadingFetchBasket"
          :is-adult="product.isAdult"
          @clickNotAvailable="handlerClickNotAvailable"
          @addToBasket="handlerClickAddToBasket"
        />
        <add-favorites
          class="product-card__add-favorites"
          :product-id="product.id"
          :price="product.prices.price"
          :old-price="product.prices.base"
          :category-id="categoryId"
          :book-id="product.bookId ? product.bookId : ''"
          :tbk-code="product.tbkCode ? product.tbkCode : ''"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useProductCard from '@/compositions/catalog/useProductCard';

import AddFavorites from '@/components/AddFavorites';
import AppPicture from '@/components/AppPicture';
import AuthorList from '@/components/ProductCard/AuthorList';
import BadgeBestPrice from '@/components/ProductCard/BadgeBestPrice';
import CardLabels from '@/components/ProductCard/CardLabels';
import CardMainButton from '@/components/ProductCard/CardMainButton';
import CardPrice from '@/components/ProductCard/CardPrice';
import ProductCardLink from '@/components/ProductCard/ProductCardLink/index.vue';
import CardBadge from '@/components/ProductCardBadge';

export default defineComponent({
  name: 'ProductCard',
  components: {
    AppPicture,
    CardPrice,
    CardBadge,
    CardLabels,
    CardMainButton,
    AuthorList,
    BadgeBestPrice,
    AddFavorites,
    ProductCardLink,
  },
  inheritAttrs: false,
  props: {
    product: { type: Object, required: true },
    withLabel: { type: Boolean, default: true },
    withBadge: { type: Boolean, default: true },
    showButtons: { type: Boolean, default: true },
    paramsLink: { type: Object, default: () => ({}) },
    // использовать ли короткое название кнопки "Нет в наличии"
    isAvailableButtonShort: { type: Boolean, default: false },
    isFetchBasketAfterAddToBasket: { type: Boolean, default: true },
    isLoadingFetchBasket: { type: Boolean, default: false },
    productIndex: { type: Number, default: 1 },
  },
  setup(props, context) {
    const productCardComposition = useProductCard(props, context);

    const handlerClickAddToBasket = (payload) => {
      context.emit('addToBasket', payload);
    };

    return {
      ...productCardComposition,
      handlerClickAddToBasket,
    };
  },
});
</script>

<style lang="less">
@import "../../assets/styles/blocks/product-card/index";
</style>
