import isEmpty from 'lodash.isempty';
import formattedError from '@/utils/formatters/formattedError';

/**
 * Возвращаем объект пагинации
 * @param data
 * @returns {{total: number, count: number, perPage: (null|number), totalPages: number, currentPage: number}}
 */
const getPagination = data => ({
  currentPage: typeof data.currentPage === 'number' ? data.currentPage : data.current_page,
  totalPages: typeof data.totalPages === 'number' ? data.totalPages : data.total_pages,
  perPage: typeof data.perPage === 'number' ? data.perPage : data.per_page,
  total: data.total,
  count: data.count,
});

/**
 * Возвращаем отформатированный список включений
 * @param {Object} relationships - объект желаемых включений
 * @param {Object} included - объект полученых включений
 * @return {{}}
 */
const getRelationships = (relationships, included) => {
  const relationshipsResult = {};
  const itemRelationships = { ...relationships } || {};
  const relationTypes = Object.keys(itemRelationships);

  if (relationTypes.length) {
    relationTypes.forEach((relationType) => {
      const relation = relationships[relationType];

      if (Array.isArray(relation.data)) {
        relationshipsResult[relationType] = [];
        relation.data.forEach((relationItem) => {
          const { type, id } = relationItem;
          included.forEach((includedItem) => {
            if (includedItem.type === type && includedItem.id === id) {
              const relTypeItem = {
                id: includedItem.id,
                type: includedItem.type,
                ...includedItem.attributes,
                relationships: getRelationships(includedItem.relationships, included),
              };
              if (isEmpty(relTypeItem.relationships)) {
                delete relTypeItem.relationships;
              }
              relationshipsResult[relationType].push(relTypeItem);
            }
          });
        });
      } else {
        const resultRel = [];
        const { type, id } = relation.data;
        included.forEach((includedItem) => {
          if (includedItem.type === type && includedItem.id === id) {
            const relTypeItem = {
              id: includedItem.id,
              type: includedItem.type,
              ...includedItem.attributes,
              relationships: getRelationships(includedItem.relationships, included),
            };
            if (isEmpty(relTypeItem.relationships)) {
              delete relTypeItem.relationships;
            }
            resultRel.push(relTypeItem);
          }
        });
        if (resultRel.length === 1) {
          relationshipsResult[relationType] = resultRel[0];
        } else {
          relationshipsResult[relationType] = resultRel.length > 1 ? resultRel : null;
        }
      }
    });
  }

  return isEmpty(relationshipsResult) ? null : relationshipsResult;
};

/**
 * Возвращаем отформатированный список
 * @param {Object} res - Объект ответа в формате Open API
 * @param {boolean} [addPagination=false] - Нужно ли добавить обработку пагинации
 * @template T
 * @return {{list: T[], pagination?}}
 */
export const buildCollection = (res, addPagination = false) => {
  const list = [];
  const { included, data } = res;
  const returnData = {
    list,
    pagination: {
      currentPage: 0,
      totalPages: 0,
      perPage: 0,
      total: 0,
      count: 0,
    },
  };

  if (addPagination && res.meta?.pagination) {
    const { meta: { pagination } } = res;
    returnData.pagination = getPagination(pagination);
  }

  if (data.length) {
    data.forEach((item) => {
      const resultRelationshipsItem = getRelationships(item?.relationships || {}, included);
      const resultItem = {
        id: item.id,
        ...item.attributes,
      };
      if (resultRelationshipsItem) {
        resultItem.relationships = resultRelationshipsItem;
      }
      list.push(resultItem);
    });
  }

  return returnData;
};

/**
 * Возвращает отформатированный элемент
 * @param {Object} res - Объект ответа в формате Open API
 * @return {*&{relationships: {}, id}}
 */
export const buildElement = (res) => {
  const { included, data } = res;
  const relationships = getRelationships(data?.relationships || {}, included);
  const result = {
    id: data.id,
    ...data.attributes,
  };
  if (relationships) {
    result.relationships = relationships;
  }
  return result;
};

/**
 * Возвращаем отформатированный список ошибок
 * @param {Object} data
 * @param {Object} [errorsList={}]
 * @returns {*[]}
 */
// eslint-disable-next-line max-len
export const buildErrorCollection = (data, errorsList = {}) => formattedError(data, errorsList);
