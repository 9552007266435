/**
 * Неразрывный пробел
 * @type {string}
 */
export const nbspCharacter = '\u00A0';

/**
 * @typedef RegionsType
 * @property {string} code - Код ФИАС города
 * @property {string} coords - Координаты центра города
 * @property {string} index - Почтовый индекс города
 * @property {string} name - Название города
 * @property {string} regionFias - ФИАС код региона
 * @property {Array<string>} regionFiasList - ФИАС коды области
 */

/**
 * Список основных регионов с их сводной информацией
 * @readonly
 * @type {Object.<string, RegionsType>}
 * @const
 */
export const MAIN_REGIONS = Object.freeze({
  msk: {
    code: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    coords: '55.7511564, 37.6223746',
    index: '101000',
    name: 'Москва',
    regionFias: '29251dcf-00a1-4e34-98d4-5c47484a36d4',
    regionFiasList: ['29251dcf-00a1-4e34-98d4-5c47484a36d4', '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'],
  },
  spb: {
    code: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    coords: '59.939095, 30.315868',
    index: '190000',
    name: 'Санкт-Петербург',
    regionFias: '6d1ebb35-70c6-4129-bd55-da3969658f5d',
    regionFiasList: ['6d1ebb35-70c6-4129-bd55-da3969658f5d', 'c2deb16a-0330-4f05-821f-1d09c93331e6'],
  },
  svp: {
    code: '6fdecb78-893a-4e3f-a5ba-aa062459463b',
    coords: '44.616495, 33.525135',
    name: 'Севастополь',
    index: '299700',
    regionFias: '6fdecb78-893a-4e3f-a5ba-aa062459463b',
    regionFiasList: ['6fdecb78-893a-4e3f-a5ba-aa062459463b'],
  },
});

/**
 * Идентификаторы основных регионов
 * @type {*[]}
 */
export const IMPORTANT_LOCATIONS = [
  '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
  'c2deb16a-0330-4f05-821f-1d09c93331e6',
];

/*
 * Коды регионов Ленинградской области
 * @type {Array}
 */
export const LENINGRAD_REGIONS = [
  {
    name: 'Бокситогорск',
    code: '1a12dc30-ca4c-480f-89dd-31452648b493',
  },
  {
    name: 'Волосово',
    code: 'eb8a9d09-6728-43fe-b3d1-21a2c804b40c',
  },
  {
    name: 'Волхов',
    code: 'd77089df-c8ed-464d-a26b-69ce42383a0f',
  },
  {
    name: 'Всеволожск',
    code: 'c70a5280-c482-4f6e-801a-cdd6851eab50',
  },
  {
    name: 'Выборг',
    code: 'a4a3e3af-ce5a-4eba-9aae-36c350fa7fec',
  },
  {
    name: 'Высоцк',
    code: 'f0a4b813-7189-4bd4-8a3a-aaa7fbed4711',
  },
  {
    name: 'Гатчина',
    code: 'd193a2f8-4ea1-406a-ba9a-698ae19caeb7',
  },
  {
    name: 'Ивангород',
    code: '752d9929-945c-4464-ae38-20a626108da9',
  },
  {
    name: 'Каменногорск',
    code: '58399f33-3e18-4921-950a-ac0fa5f9e97c',
  },
  {
    name: 'Кингисепп',
    code: 'ef14bc6b-dd76-4264-bb40-c0fd8a60985b',
  },
  {
    name: 'Кириши',
    code: '52da70f1-08b0-4b4c-9d22-d67a62bc2aa5',
  },
  {
    name: 'Кировск',
    code: 'a1535e5e-c151-431a-a4c4-954d4545e37a',
  },
  {
    name: 'Коммунар',
    code: '459dca99-04ff-44fb-af9f-7e2100ec28ac',
  },
  {
    name: 'Лодейное Поле',
    code: '5f52edf9-92aa-4e69-9314-e629c45ec1d5',
  },
  {
    name: 'Луга',
    code: 'd9135f5d-627e-441d-b208-9087ee0367dd',
  },
  {
    name: 'Любань',
    code: 'cda96dc0-75b2-411b-b995-16cc63fcfa05',
  },
  {
    name: 'Никольское',
    code: '43cb88de-fa89-4027-b3ef-84deb2d32ce5',
  },
  {
    name: 'Новая Ладога',
    code: '2145f2cb-3ec2-4c1d-bd52-f84b2858c6a9',
  },
  {
    name: 'Отрадное',
    code: 'c4602384-ff62-4e0f-bf4b-06a5c653702b',
  },
  {
    name: 'Пикалево',
    code: '0a243487-acce-4775-979a-5a08e8c62da6',
  },
  {
    name: 'Подпорожье',
    code: '6d13b512-6d25-413a-ab29-3d9ade960f3a',
  },
  {
    name: 'Приморск',
    code: '878aa7cc-76f0-42c8-90f5-3489f43c169c',
  },
  {
    name: 'Приозерск',
    code: '59a9c284-72fb-49e6-8380-10ca044d0bd7',
  },
  {
    name: 'Светогорск',
    code: 'd4789972-b71d-4e70-9af8-f4acfcf80c71',
  },
  {
    name: 'Сертолово',
    code: '79b806cd-e36c-48fc-b82c-8cbb5cfd9450',
  },
  {
    name: 'Сланцы',
    code: 'a7a1a8e5-45b2-4542-b718-0976702ccd90',
  },
  {
    name: 'Сосновый Бор',
    code: 'f4a4b31f-9f0a-4fdb-804c-d67661085eb4',
  },
  {
    name: 'Сясьстрой',
    code: 'f59186fa-b4db-4b2d-88b9-e3fde4a90690',
  },
  {
    name: 'Тихвин',
    code: '86ec26f6-d980-4c40-b4ce-77e1638ec8ad',
  },
  {
    name: 'Тосно',
    code: '165119d1-6555-4473-8b90-46ec517b4076',
  },
  {
    name: 'Шлиссельбург',
    code: '5a543514-a133-431c-919e-8a61c2261783',
  },
];

/*
 * Коды городов Санкт-Петербурга
 * @type {Array}
 */
export const SPB_CITIES = [
  {
    name: 'Зеленогорск',
    code: 'ac598324-b704-4957-a66e-e8142677981b',
  },
  {
    name: 'Колпино',
    code: '1e11a121-81c4-4b66-b11c-da8f47db2e1d',
  },
  {
    name: 'Красное Село',
    code: 'cced82af-3bdf-4a91-ab68-d654804767c0',
  },
  {
    name: 'Кронштадт',
    code: '7b1c7427-41b2-4ffc-bf39-550e8299b5cb',
  },
  {
    name: 'Ломоносов',
    code: '3c2827f3-63f3-4692-8c74-c1318ee524fd',
  },
  {
    name: 'Павловск',
    code: '5c348da7-3a1e-47fe-be8d-52bac0887cd4',
  },
  {
    name: 'Петергоф',
    code: '8f238984-812b-4bb1-850b-49749fb5c56d',
  },
  {
    name: 'Пушкин',
    code: '110d6ad9-0b64-47cf-a2ee-7e935228799c',
  },
  {
    name: 'Сестрорецк',
    code: 'ee5e852c-1a4a-4aee-919a-54473a76fa4d',
  },
];

/*
 * LocationCode Зеленограда
 * @type {string}
 */
export const ZELENOGRAD_LOCATION_CODE = 'ec44c0ee-bf24-41c8-9e1c-76136ab05cbf';

/*
 * Номер телефона нашего кол-центра
 * @type {Array}
 */
export const CALL_CENTER_PHONE = [
  '8 (495) 268-08-41',
  '8 (800) 333-65-23',
];

/**
 * Время работы колл центра
 * @type {string}
 */
export const CALL_CENTER_TIME = '8:00 - 20:00 (Мск)';

/**
 * Длина номера купона Много.ру для валидации в личном кабинете
 * @type {number}
 */
export const MNOGORU_COUPON_LENGTH = 8;

/**
 * Минимальный рейтинг пользователя
 * @type {number}
 */
export const MIN_BOOK_RATING = 4;

/**
 * Длина даты в формате dd.mm.yyyy
 * @type {number}
 */
export const CORRECT_DATE_LENGTH = 10;

/**
 * Время ожидания для повторной отправки кода по смс
 * @type {number}
 */
export const SMS_RESENDING_TIMEOUT = 60 * 20 * 1000;

/**
 * Обозначение валюты
 * @type {string}
 */
export const CURRENCY = 'р.';

/**
 * Текст непредвиденной ошибки
 * @type {string}
 */
export const ERROR_MESSAGE = 'Что-то пошло не так, попробуйте повторить попытку позже';

/**
 * Домен для кук на демо
 * @type {string}
 */
export const DEMO_DOMAIN = 'ssr.devel.xmo';

/**
 * Домен для кук на проде
 * @type {string}
 */
export const PROD_DOMAIN = 'book24.ru';

/**
 * Код РФ в формате ISO 3166-2
 * @type {string}
 */
export const ISO_CODE_RUSSIA = 'RU';

/**
 * Код Украины в формате ISO 3166-2
 * @type {string}
 */
export const ISO_CODE_UKRAINE = 'UA';

/**
 * Успешный статус ответа бекенда
 * @type {string}
 */
export const SUCCESS_STATUS = 'OK';

/**
 * Флаг для скролла к каталогу
 * @type {string}
 */
export const CATALOG_MARK_SCROLL = 'CATALOG_MARK_SCROLL';

/**
 * Урл основного сайта
 * @type {string}
 */
export const MAIN_URL = 'https://book24.ru';

/**
 * Контрольные точки
 * @type {Readonly<{tablet: number, desktop: number, mobile: number}>}
 */
export const BREAKPOINTS = Object.freeze({
  mobile: 900,
  tablet: 1200,
  desktop: 1400,
});

/**
 * Дефолтный объект пагинации
 * @type {Readonly<{total: number, perPage: number, totalPages: number, count: number, currentPage: number}>}
 */
export const DEFAULT_PAGINATION = Object.freeze({
  currentPage: 0,
  totalPages: 0,
  perPage: 0,
  total: 0,
  count: 0,
});

export const COUNT_TEXT_LIST = Object.freeze({
  one: {
    value: 1,
    label: 'одного',
  },
  two: {
    value: 2,
    label: 'двух',
  },
  three: {
    value: 3,
    label: 'трёх',
  },
  getLabelByValue(value) {
    const list = Object.values(this);
    const item = list.find(itemVal => itemVal?.value === value);
    return item || null;
  },
});

/**
 * Ошибка от cloudpayments если пользователь закрыл окно с оплатой
 * @type {string}
 */
export const CLOUDPAYMENTS_USER_CANCEL_ERROR = 'User has cancelled';

/**
 * Ошибка 404
 * @type {Readonly<{code: string, message: string}>}
 */
export const ERROR_NOT_FOUND = Object.freeze({
  code: '12345678-16bb-4a0a-8a82-30d4ca5f6da8',
  message: 'Элемент не найден',
});

/**
 * Ошибка 500
 * @type {Readonly<{code: string, message: string}>}
 */
export const ERROR_SERVER_ERROR = Object.freeze({
  code: '14b89645-67ce-4055-87d4-7aaac3a809f5',
  message: 'Внутренняя ошибка сервера',
});

/**
 * Ошибка если произошло что то непредвиденное
 * @type {Readonly<{code: string, message: string}>}
 */
export const UNEXPECTED_ERROR = Object.freeze({
  code: '0',
  message: 'Произошла ошибка. Пожалуйста, попробуйте позже.',
});

/**
 * Ошибка 401
 * @type {Readonly<{code: string, message: string}>}
 */
export const ERROR_USER_NOT_AUTH = Object.freeze({
  code: 'd4a0eed6-26bb-4c71-bed7-86b7b154443f',
  message: 'Пользователь не авторизован',
});

/**
 * Месяц в миллисекундах
 * @type {number}
 */
export const MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000;

/**
 * Час в миллисекундах
 * @type {number}
 */
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;

/**
 * @typedef {Object} RequisiteType
 * @property {string} label - название
 * @property {string} value - значение
 */

/**
 * Реквизиты компании
 * @readonly
 * @type {Object.<string, RequisiteType>}
 */
export const COMPANY_REQUISITES = Object.freeze({
  company: {
    label: 'Компания',
    value: 'ООО «Новый Книжный Центр»',
  },
  ogrn: {
    label: 'ОГРН',
    value: '1027700282763',
  },
  inn: {
    label: 'ИНН',
    value: '7710422909',
  },
  bik: {
    label: 'БИК',
    value: '044525411',
  },
  kpp: {
    label: 'КПП',
    value: '780701001',
  },
  codeOKPO: {
    label: 'Код ОКПО',
    value: '58541769',
  },
  codeOKVED: {
    label: 'Код ОКВЭД',
    value: '47.91',
  },
  bank: {
    label: 'Банк',
    value: 'ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)',
  },
  correspondentAccount: {
    label: 'К/С',
    value: '№ 30101810145250000411',
  },
  bankAccount: {
    label: 'Р/С',
    value: '№ 40702810819800000471',
  },
  mailAddress: {
    label: 'Почтовый адрес',
    value: '127322, г. Москва, Огородный проезд, дом 20, строение 27, ООО «Новый Книжный Центр»',
  },
  address: {
    label: 'Юридический адрес',
    value: `198516, г.${nbspCharacter}Санкт-Петербург, вн.тер.г. город Петергоф, пр-кт. Санкт-Петербургский, д. 60, литера А, помещ. 2-Н/помещ. 273/офис 411.`,
  },
});

/**
 * Список типов размеров
 * @readonly
 * @enum {TypeSizes}
 * @const
 */
export const TYPE_ELEMENT_SIZES = Object.freeze({
  normal: {
    value: 'normal',
    className: '',
  },
  small: {
    value: 'small',
    className: '_small',
  },
  extraSmall: {
    value: 'extra-small',
    className: '_extra-small',
  },
  large: {
    value: 'large',
    className: '_large',
  },
});

/**
 * Список типов кнопки
 * @readonly
 * @enum {TypeSizes}
 * @const
 */
export const TYPES_BUTTON = Object.freeze({
  default: {
    value: 'default',
    className: '',
  },
  secondary: {
    value: 'secondary',
    className: '_secondary',
  },
  more: {
    value: 'more',
    className: '_more',
  },
  link: {
    value: 'link',
    className: '_link',
  },
  linkDashed: {
    value: 'link-dashed',
    className: '_link-dashed',
  },
});

/**
 * Корпоративная почта для b2b клиентов
 * @type {string}
 */
export const CORP_EMAIL = 'corp@book24.ru';

/**
 * Типы платформ
 * @type {Readonly<{desktop: string, mobile: string}>}
 * @const
 */
export const TYPES_PLATFORM = Object.freeze({
  mobile: 'mobile',
  desktop: 'desktop',
});

/**
 * Заголовок страницы чекаута
 * @type {string}
 */
export const TITLE_PAGE_ORDER = 'Оформление заказа';

/**
 * Заголовок страницы чекаута предзаказа
 * @type {string}
 */
export const TITLE_PAGE_PER_ORDER = 'Оформление предзаказа';

/**
 * Урл страницы корзины
 * @type {string}
 */
export const CART_PAGE = '/personal/cart/';

export const FORGOT_TEXT = 'Укажите адрес электронной почты, с которым вы регистрировались ранее, и мы вышлем инструкции по смене пароля.';

export const DEFAULT_ERROR_TEXT = 'Произошла ошибка, попробуйте позже или обратитесь в клиентскую службу.';

export const LOCAL_CONST_CUSTOMERS = 'b24-customer';

export const LOCAL_CONST_BASKET = 'b24-basket';

/**
 * Название куки для записи значения utm_source
 * @type {string}
 */
export const DISCOUNT_URL_REQUEST = 'b24DiscountUrlRequest';

/**
 * Минимальное количество при котором показываем сколько товаров осталось
 * @type {number}
 */
export const MIN_QUANTITY_THINGS = 15;

/**
 * Максимальное количество товаров которое можно выбрать в корзине
 * @type {number}
 */
export const MAX_COUNT_BASKET = 1000;

/**
 * Максимальное количество символов в комментарии для курьера
 * @type {number}
 */
export const MAX_LENGTH_COMMENT_COURIER = 250;

export const DISCOUNT_BUNDLE_TWO_PRODUCTS = 3;

export const DISCOUNT_BUNDLE_THREE_PRODUCTS = 5;

/**
 * Код рубля
 * @type {string}
 */
export const ROUBLE_CODE = 'RUB';

